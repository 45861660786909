/* global charlie */

// Inline external svg use links
import svg4everybody from 'svg4everybody';

// Fix device width for IE10 on windows 8.
(function fixIE10DeviceWidth() {
  if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
    const msViewportStyle = document.createElement('style');

    msViewportStyle.appendChild(document.createTextNode('@-ms-viewport{width:auto!important}'));
    document.querySelector('head').appendChild(msViewportStyle);
  }
}());

// Give css the ability to check if JavaScript is available; this is to keep
// backwards compatibility with some old Modernizr features. Modernizr has been
// removed as we don't really use it at the moment...
$('html').removeClass('no-js').addClass('js');

// Set-up lazy loading.
charlie.onReady(() => {
  (function lazyLoadBGs() {
    const lazyLoadBg = document.getElementsByClassName('js-lazy-load-bg');

    for (let i = 0; i < lazyLoadBg.length; i += 1) {
      lazyLoadBg[i].style.backgroundImage = `url(${lazyLoadBg[i].getAttribute('data-src')})`;
    }
  }());

  (function lazyLoadImages() {
    const lazyLoadImage = document.getElementsByClassName('js-lazy-load-image');

    for (let i = 0; i < lazyLoadImage.length; i += 1) {
      lazyLoadImage[i].src = lazyLoadImage[i].getAttribute('data-src');
    }
  }());
});

charlie.onReady(() => {
  svg4everybody({
    fallback: function setSVGFallback() {
      return 'fallback.png'; // always return fallback.png
    },
    nosvg: false, // shiv <svg> and <use> elements and use image fallbacks
    polyfill: true, // polyfill <use> elements for External Content
  });
});
