/* global $, ClipboardJS */

import DesignSystemInputErrors from './js/input-errors'
import DesignSystemShowPassword from './js/show-password'
import DesignSystemInputState from './js/input-state'

class DesignSystemInput {
  constructor (input) {
    // this.input = input[0]
  }

  init () {
    this.copyToClipBoard()
    this.showPassword()
  }

  copyToClipBoard () {
    if ($('.js-copy-to-clipboard').length > 0) {
      new ClipboardJS('.js-copy-to-clipboard')
    }
  }

  showPassword () {
    $('.js-show-password-button').each((index, button) => {
      const $button = $(button)
      if (!$button.data('instance')) {
        $button.data('instance', new DesignSystemShowPassword($button))
        $button.data('instance').bindEvents()
      }
    })
  }
}

$(document).on('ready ajaxComplete', () => {

  $('.js-input').each((index, target) => {
    const $input = $(target)
    if (!$input.data('initialized')) {
      new DesignSystemInput($input)
      new DesignSystemInput($input).init()

      // JS error handling.
      $input.data('input', new DesignSystemInputErrors($input))
      $input.data('state', new DesignSystemInputState($input))

      $input.data('initialized', true)
    }
  })
})
