class Tombstone {
  /**
   * When called this makes a request to the endpoint in Charlie to mark this
   * tombstone as a vampire.
   *
   * The location is used to identify which tombstone this refers to. Although
   * we could get this programmatically when adding tombstones to the DB we
   * still need a way to identify the tombstone that's in fact a vampire when
   * it's called so we manually specify the location and send it through an Ajax
   * request when the code is run.
   *
   * Who placed the tombstone, and when, gets defined in a comment in the file
   * that's being tested. The comment will get removed during minification so
   * this prevents and data other than the location being made available to the
   * user. The location is findable from the source map so NBD.
   *
   * Example tombstone placement:
   *   tombstone.place('partial/path/to/file.js#functionName#123'); // Tombstone placed on 2020-0729 by R. Zombie.
   *
   * The scanner will parse out the date, the developer that placed it and grab
   * the location from the tombstone.place('xxxx') call in the file and add it
   * to the tombstones table as with the Ruby tombstones.
   *
   * @param {String} location  Location of the tombstone. This is used to ID it in the DB.
   */
  place(location) {
    $.ajax('/mark_call', {
      method: 'POST',
      data: { caller: window.location.pathname, location },
    });
  }
}

const tombstone = new Tombstone();
export default tombstone;
