/* global $ */

class DesignSystemPanel {
  constructor ($panel) {
    this.$panel = $panel[0]
  }

  init () {
    $('.js-panel__show').on('click', () => {
      this.$panel.setAttribute('data-show', '')
      this.$panel.style.visibility = "visible"
    })

    $('.js-panel__show').on('keyup', (e) => {
      if (e.which == 13) {
        this.$panel.setAttribute('data-show', '')
        this.$panel.style.visibility = "visible"
      }
    })

    $('.js-panel__controls').on('click', () => {
      this.closePanel()
    })

    $('.js-c-panel__controls-close').on('click', () => {
      this.closePanel()
    })

    $(document).on('keyup', (e) => {
      if (e.which == 27) {
        this.closePanel()
      }
    })

    // clicking outside the panel to close it breaks in Lookbook
    if (!(window.location.pathname.indexOf('lookbook') > -1)) {
      $(document).on('click', (e) => {
        if (!this.isPanelTrigger(e.target) && !this.isInPanel(e.target)) {
          this.closePanel()
        }
      })
    }
  }

  closePanel () {
    if ($(this.$panel).attr('data-show') === '') {
      this.$panel.removeAttribute('data-show')
      this.$panel.style.visibility = "hidden"
    }
  }

  isPanelTrigger (element) {
    if ($('.js-panel__show').length > 1) { // for date button as link in report metric filters
      return $('.js-panel__show')[0] === $(element).closest('.c-button')[0] || $('.js-panel__show')[1] === $(element).closest('.c-button--as-link')[0]
    } else {
      return $('.js-panel__show')[0] === $(element).closest('.c-button')[0]
    }
  }

  isInPanel (element) {
    if ($(this.$panel).attr('data-show') === '') {
      return ($(element).closest('.js-panel').length > 0)
    }
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-panel').each((_, target) => {
    const $panel = $(target)

    if (!$panel.data('initialized')) {
      $panel.data('instance', new DesignSystemPanel($panel))
      $panel.data('instance').init()
      $panel.data('initialized', true)
    }
  })
})
