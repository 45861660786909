/**
 * Handle the Schema preview link interactions in the launch flow.
 */
export default class SchemaLink {
  init() {
    if ($('.js-schema-preview-link').length > 0) {
      this.initPreviewLink();
    }

    if ($('.js-schema-link').length > 0) {
      this.initQuoteLink();
    }
  }

  /**
   * Builds the preview template link on the second step of the creation flow.
   *
   * When the select input changes the final character of the href attribute on
   * the preview link is changed to the ID in the select.
   */
  initPreviewLink() {
    $('.js-schema-preview-link').click((event) => {
      const $element = $(event.currentTarget);

      // Lowercase "l" in "previewlink" is deliberate - jQuery will change the
      // data attributes name to this.
      const linkTarget = $('.js-schema-select .js-select__input-field')
        .find(':selected').data('previewlink');

      $element.attr('href', linkTarget);
    }).data('initialized', true);
  }

  /**
   * Select a template in the drop down when a link is clicked in the quote
   * on the template selection page of the launch flow.
   */
  initQuoteLink() {
    $('.js-schema-link').click((event) => {
      event.preventDefault();

      const $element = $(event.currentTarget);
      $('#reviews_launch_steps_schema_form_schema')
        .find(`option[value = "${$element.data('schemaid')}"]`)
        .prop('selected', true);
    });
  }
}
