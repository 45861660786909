$(document).on('ready', function() {
  var toggleOverlay = function(trigger) {
    var $target = $(trigger.data('target'));
    var $container = $(trigger.data('container'));

    if ($target.hasClass('is-showing')) {
      $container.removeClass('u-body--overlay-open');
      $(window).scrollTop(0);
      $target.removeClass('is-showing');
    } else {
      $container.addClass('u-body--overlay-open');
      $(window).scrollTop(0);
      $target.addClass('is-showing');
    }
  };

  $(document).on('click', function(event) {

    // Hide modal overlays when you click off of specified targets
    if (!clickedInsideOf(event.target, '.js-sidebar, .js-toggle-overlay, .menu-extension-close-icon')) {
      $('.js-sidebar').removeClass('is-showing');
      $('body').removeClass('u-body--overlay-open');
    }

    if (!clickedInsideOf(event.target, '.js-actions-overlay, .js-search-overlay, .js-search-input, .js-toggle-overlay')) {
      $('.js-actions-overlay, .js-search-overlay').removeClass('is-showing');
      $('.js-page-wrapper').removeClass('u-body--overlay-open');
    }

    // Hide modal overlays when you click inside of specified targets
    if (clickedInsideOf(event.target, '.js-close-overlay')) {
      $('.js-actions-overlay, .js-search-overlay').removeClass('is-showing');
      $('.js-page-wrapper').removeClass('u-body--overlay-open');
    }
  });

  // Escape key closes overlays
  $(window).on('keyup', function(e) {
    if (e.keyCode === 27) {
      $('.js-actions-overlay, .js-search-overlay').removeClass('is-showing');
    }
  });

  $('.js-toggle-overlay').on('click', function() {
    toggleOverlay($(this));
  });
});
