class DesignSystemChecklistTask {
  constructor (checklistTask) {
    this.checklistTask = checklistTask[0]
    this.checklistInput = this.checklistTask.querySelector('.js-checklist-task__check')
  }

  init() {
    this.checklistInput && this.checklistInput.addEventListener('click', () => {
      this.updateChecklistTask()
    })
  }

  updateChecklistTask() {
    const task_checked = this.checklistTask.dataset.checked === 'false' ? true : false
    const action_url = this.checklistTask.dataset.actionUrl

    this.checklistTask.classList.toggle("c-checklist_task--checked")
    this.checklistTask.dataset.checked = task_checked
    this.updateChecklistGraph()

    if (action_url) {
      $.ajax({
        type: "PATCH",
        dataType: "script",
        url: action_url,
        data: {
          checklist_item: {
            checked: task_checked
          },
          ui_updated: true
        }
      })
    } else {
      console.warn('Could not create update path')
    }
  }

  updateChecklistGraph() {
    // Figure out new checklist completeness
    const checklistTaskGroup = this.checklistTask.closest('.js-checklist_task_group')
    if (checklistTaskGroup) {
      const all_tasks = checklistTaskGroup.querySelectorAll('.js-checklist-task').length
      const all_completed_tasks = checklistTaskGroup.querySelectorAll('.js-checklist-task.c-checklist_task--checked').length
      const skippable_tasks =  checklistTaskGroup.querySelectorAll('.js-checklist-task.js-skip-counter').length
      const skippable_completed_tasks = checklistTaskGroup.querySelectorAll('.js-checklist-task.c-checklist_task--checked.js-skip-counter').length

      const number_of_completed_tasks = (all_completed_tasks - skippable_completed_tasks)
      const number_of_tasks = (all_tasks - skippable_tasks)

      const percentage = number_of_completed_tasks / number_of_tasks * 100
      const percentage_as_text = number_of_completed_tasks + '/' + number_of_tasks

      // Setup new chart data
      const chart = checklistTaskGroup.querySelector('.js-doughnut_chart').data.chart
      const style = percentage > 70 ? '#50e2c3' : '#e1542d'

      chart.data.datasets[0]['data'] = [percentage, 100 - percentage]
      chart.data.datasets[0]['backgroundColor'] = [style, "#f2f2f2"]
      chart.data.datasets[0]['hoverBackgroundColor'] = [style, "#f2f2f2"]

      chart.options.animation.duration = 200

      // Update DOM
      checklistTaskGroup.querySelector('.js-doughnut_chart__label').textContent = percentage_as_text
      chart.update()
    }
  }
}

$(document).on('ready ajaxComplete', () => {
  if ($('.js-checklist-task').length) {
    $('.js-checklist-task').each((_, target) => {
      const $checklistTask = $(target)

      if (!$checklistTask.data("initialized")) {
        $checklistTask.data('instance', new DesignSystemChecklistTask($checklistTask))
        $checklistTask.data('instance').init()
        $checklistTask.data("initialized", true)
      }
    })
  }
})
