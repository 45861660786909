$(document).on('ready ajaxComplete', function() {
  var $tabs         = $('.js-tab-select'),
      $targets      = $('.js-tab-target'),
      $input        = $('.js-tab-input-select'),
      $default      = '',
      $preSelectTab = '',
      $preSelectText = '';

  // Check to see whether the elements exist in the document
  if ($tabs.length) {
    // Immeditely invoked function to return page load variables

    (function preSelect(val) {
      $default = $input.val();

      // Find the tab that has been pre-selected via the select field
      if (val === undefined) {
        $preSelectTab  = $('.js-tab-select[data-permission="' + $default + '"]');
        $preSelectText = $('.js-tab-target[data-permission="' + $default + '"]');
      } else {
        $preSelectTab  = $('.js-tab-select[data-permission="' + val + '"]');
        $preSelectText = $('.js-tab-target[data-permission="' + val + '"]');
      }

      // Remove any pre-selection from the incorrect targets
      $tabs.not($preSelectTab).removeClass('is-selected');
      $targets.not($preSelectTab).removeClass('is-selected');

      // Apply is selected to the relevant elements
      $preSelectTab.addClass('is-selected');
      $preSelectText.addClass('is-selected');
    }());

    $tabs.on('click', function() {
      var $tab        = $(this),
          $permission = $(this).data('permission'),
          $target     = $('.js-tab-target[data-permission="' + $permission + '"]');

      if ($tab.hasClass('is-locked')) {
        // Do nothing
      } else {
        $tabs.not($tab).removeClass('is-selected');
        $targets.not($target).removeClass('is-selected');
        $tab.addClass('is-selected');
        $target.addClass('is-selected');
        $input.val($permission);
      }
    });
  }

  $input.on('change', function() {
    var $permission = $input.val(),
        $tab        = $('.js-tab-select[data-permission="' + $permission + '"]'),
        $target     = $('.js-tab-target[data-permission="' + $permission + '"]');

    $tabs.not($tab).removeClass('is-selected');
    $targets.not($target).removeClass('is-selected');
    $tab.addClass('is-selected');
    $target.addClass('is-selected');
  });
});
