export default class Expand {
  init() {
    this.hideAll();
    this.events();
    this.toggleIcons().removeClass('permission-roles__toggle--hidden');
  }

  events() {
    this.expandAllButton().click(() => this.showAll());
    this.collapseAllButton().click(() => this.hideAll());

    this.headerCells().click((e) => {
      $(e.target)
        .closest('.permission-roles__head-row')
        .toggleClass('permission-roles__head-row--open');
      $(e.target)
        .closest('.permission-roles__body')
        .find(`.${this.bodyRowClass()}`)
        .toggleClass(this.hiddenClass());
      $(e.target)
        .find(`.${this.toggleIconClass()}`)
        .toggleClass(this.toggleIconOpenClass());
    });
  }

  hideAll() {
    this.toggleableHeaders().removeClass('permission-roles__head-row--open');
    this.toggleableRows().addClass(this.hiddenClass());
    this.toggleIcons().removeClass(this.toggleIconOpenClass());
  }

  showAll() {
    this.toggleableHeaders().addClass('permission-roles__head-row--open');
    this.toggleableRows().removeClass(this.hiddenClass());
    this.toggleIcons().addClass(this.toggleIconOpenClass());
  }

  /**
   * Classes.
   */
  hiddenClass() {
    return 'permission-roles__body-row--hidden';
  }

  toggleIconClass() {
    return 'permission-roles__toggle';
  }

  toggleIconOpenClass() {
    return 'permission-roles__toggle--open';
  }

  bodyRowClass() {
    return 'permission-roles__body-row';
  }

  /**
   * Selectors.
   */
  toggleableRows() {
    return $('.permission-roles__table .permission-roles__body .permission-roles__body-row');
  }

  toggleableHeaders() {
    return $('.permission-roles__table .permission-roles__head-row');
  }

  expandAllButton() {
    return $('.js-permission-roles__expand-all-btn');
  }

  collapseAllButton() {
    return $('.js-permission-roles__collapse-all-btn');
  }

  headerCells() {
    return $('.permission-roles__head-row');
  }

  toggleIcons() {
    return $(`.${this.toggleIconClass()}`);
  }
}
