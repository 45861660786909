// ------------------------------------------------\\
// Charlie helper functions
// ------------------------------------------------\\

var clickedInsideOf = function(target, targets) {
  return $(target).closest(targets).length;
};

// Global JS Function
// We're using this function in our HTML files
window.clickedInsideOf = clickedInsideOf;

function charlieScrollTo(thing, timing) {
  if (typeof thing !== 'undefined') {
    var docPosition = thing.offset().top - 150;
    var pagePosition = $('.js-page-wrapper').scrollTop();
    var scrollTo = pagePosition + docPosition;
    var scrollTiming = (typeof timing !== 'undefined') ? timing : 400;

    $('html, body').animate({ scrollTop: scrollTo, }, scrollTiming);
  }
}
// Global JS Function
// We're using this function in our HTML files
window.charlieScrollTo = charlieScrollTo

$(document).on('ready ajaxComplete', function() {

  // Toggle edit
  if (!$('.js-toggle-edit').data('init')) {
    $('.js-toggle-edit').data('init', true);

    $('.js-toggle-edit').on('click', function() {
      $(this).closest('.js-toggle-edit-container').toggleClass('is-editing');
    });
  }
});

// Extensions of the Array prototype
Array.prototype.remove = function() {
  var what, a = arguments, L = a.length, ax;

  while (L && this.length) {
    what = a[ --L ];
    while ((ax = this.indexOf(what)) !== -1) {
      this.splice(ax, 1);
    }
  }

  return this;
};

Array.min = function( array ) {
  return Math.min.apply( Math, array );
};

// Turn URL bar in to a hash object
$.extend({
  getQueryParameters: function(str) {
    return (str || document.location.search).replace(/(^\?)/, '').split('&').map(function(n) {return n = n.split('='), this[ n[ 0 ] ] = n[ 1 ], this; }.bind({}))[ 0 ];
  },
});

// Create public activities

function createActivity(action, source, parameters = {}) {
  $.ajax({
    type: "POST",
    dataType: "script",
    url: '/activities',
    data: {
      activity_action: action,
      source: source,
      ...parameters
    }
  })
  .done(function() {
  })
  .fail(function() {
    appsignal.sendError(new Error('Could not create activity. activityAction: ' + action));
  });
};

// Global JS Function
// We're using this function in our HTML files
window.createActivity = createActivity
