/* global $ */

class DesignSystemRadioButton {
  constructor ($radioButton) {
    this.$radioButton = $radioButton[0]
    this.$input = this.$radioButton.querySelector('.js-radio-button__input')
    this.$radioGroup = this.$radioButton.closest('.js-radio-group')
    this.$drawer = this.$radioButton.querySelector('.js-radio_button__drawer')
  }

  init () {
    if (this.$input) {
      this.$input.addEventListener('click', () => {
        if (this.$drawer) {
          this.handleDrawers()
        }

        if (this.$input.dataset.addControl || this.$input.dataset.removeControl) {
          this.handleVisibilityToggling()
        }
      })
    }
  }

  handleDrawers () {
    // Hide all content drawers for the parent radio group
    if (this.$radioGroup) {
      this.$radioGroup.querySelectorAll('.js-radio_button__drawer').forEach((drawer) => {
        drawer.classList.remove('is-open')
      })
    }

    // Show this radio buttons content drawer
    this.$drawer.classList.add('is-open')
  }

  handleVisibilityToggling() {
    const inputData = this.$input.dataset
    const $addControl = document.querySelector(inputData.addControl)
    const $removeControl = document.querySelector(inputData.removeControl)

    if ($addControl) {
      $addControl.classList.add(inputData.addState)
    }

    if ($removeControl) {
      $removeControl.classList.remove(inputData.removeState)
    }
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-radio-button').each((index, radioButton) => {
    const $radioButton = $(radioButton)

    if (!$radioButton.data('initialized')) {
      $radioButton.data('instance', new DesignSystemRadioButton($radioButton))
      $radioButton.data('instance').init()
      $radioButton.data('initialized', true)
    }
  })
})
