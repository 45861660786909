/**
 * Handle the reminder switch on the reminders launch step.
 */
export default class Reminders {
  /**
   * Initialise the object.
   */
  init() {
    if ($('.js-future-reminder').length > 0) {
      this.initToggle();
    }
  }

  /**
   * Show or hide the future reminder date model.
   */
  initToggle() {
    this.toggleLogic();

    $('#run_again_reminder_enabled')
      .parent('.js-switch')
      .click(() => this.toggleLogic());
  }

  /**
   * Define the logic that determines if the date box should be shown or not.
   */
  toggleLogic() {
    const reminderContainer = $('.js-future-reminder');
    const toggle = $('#run_again_reminder_enabled');

    reminderContainer.hide();
    if (toggle.is(':checked')) {
      reminderContainer.show();
    }
  }
}
