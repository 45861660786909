(function( $ ) {
  if (!$.charlie) {
     $.charlie = {};
  }

  $.charlie.annotations = function() {
    var base = this;

    base.positionArrow = function(term) {
      var clause = $('#clause_' + term);
      var advice = $('#advice_' + term);
      var arrowWrapper = $('#arrow_' + term);
      var arrow = arrowWrapper.find('.js-arrow-line');

      var clausePos = clause[ 0 ].getBoundingClientRect();
      var advicePos = advice[ 0 ].getBoundingClientRect();
      var arrowPos = { 'min-height': 100, left: clausePos.right + 10, };

      var clauseTop = clause.offset().top;
      var adviceTop = advice.offset().top;
      var adviceBottom = adviceTop + advice.height();

      var arrowHeight;
      var arrowWidth = advicePos.left - clausePos.right;

      var clauseAboveAdvice = clauseTop <= adviceTop;
      var clauseLevelWithAdvice = (clauseTop > adviceTop) && (clauseTop < adviceBottom);

      if (clauseAboveAdvice && !clauseLevelWithAdvice) {
        arrowHeight = (adviceTop - clauseTop) + 15;
        arrowPos.top = clauseTop;
        arrowWrapper.addClass('u-arrow--is-up');
        arrowWrapper.removeClass('u-arrow--is-down');

      } else if (clauseLevelWithAdvice) {
        arrowHeight = clauseTop - adviceTop;
        arrowPos.top = adviceTop + 15;
        arrowWrapper.addClass('u-arrow--is-down');
        arrowWrapper.removeClass('u-arrow--is-up');

      } else {
        arrowHeight = (clauseTop - adviceBottom) + 15;
        arrowPos.top = adviceBottom;
        arrowWrapper.addClass('u-arrow--is-down');
        arrowWrapper.removeClass('u-arrow--is-up');
      }

      arrow.height(arrowHeight);
      arrow.width(arrowWidth);
      arrow.css({ 'min-height': 100, });
      arrowWrapper.height(arrowHeight);
      arrowWrapper.width(arrowWidth);
      arrowWrapper.css(arrowPos);
    };

    base.positionAdvice = function(term) {
      var advice = $('#advice_' + term);
      var clause = $('#clause_' + term);
      var adviceConatiner = $('.js-generator-advice');
      var clauseTop = clause.offset().top;
      var adviceConatinerTop = adviceConatiner.offset().top;
      var adviceTop = (clauseTop - adviceConatinerTop) - 100;

      advice.css({ 'top': adviceTop, });
    };

    base.repositionAdvice = function() {
      var $advice = $('.js-advice');
      var adviceConatiner = $('.js-generator-advice');
      var adviceBottoms = [];
      var previousBottom;

      if ($advice.length) {
        $advice.each(function() {
          var adviceTop = ($(this).offset().top - adviceConatiner.offset().top);
          var adviceBottom = adviceTop + $(this).height();
          previousBottom = adviceBottoms[ adviceBottoms.length - 1 ];

          if (adviceBottoms.length) {
            if (previousBottom > adviceTop) {
              $(this).css({ top: (previousBottom + 30), });
            }
          }

          adviceBottoms.push(adviceBottom);
          adviceConatiner.css({ 'min-height': adviceBottom, });
        });
      }
    };

    base.hideArrows = function() {
      var $arrows = $('.js-arrow-line-wrapper');

      $arrows.each(function() {
        var advice = $('#advice_' + $(this).data('id'));

        if (advice.length) {
          $(this).removeClass('is-hidden');
        } else {
          $(this).addClass('is-hidden');
        }
      });
    };

    base.init = function() {
      var $advice = $('.js-advice');
      $.charlie.hideArrows();

      if ($advice.length) {

        $advice.each(function() {
          var term = $(this).data('id');
          $.charlie.positionAdvice(term);
          $.charlie.repositionAdvice();
          $.charlie.positionArrow(term);
        });

        $.charlie.repositionAdvice();
      }
    };

    base.init();

  };
})( jQuery );
