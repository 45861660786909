/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import Dropzone from "../../../../vendor/assets/javascripts/dropzone";

// ? These methods probably won't disable any submit buttons anymore? Is that still needed?

// Default options for dropzone
Dropzone.autoDiscover = false;
Dropzone.dictFileTooBig = 'File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.';
Dropzone.dictInvalidFileType = 'You can\'t upload files of this type. Make sure it\'s an image or pdf';
Dropzone.dictResponseError = 'Upload failed. Server responded with {{statusCode}} code.';

function disableNavigation() {
  var submitBtn = $('.js-btn-prev, .js-btn-next');
  submitBtn.addClass('btn--disabled').prop('disabled', true);
}

// Global JS Function
// We're using this function in our HTML files
window.disableNavigation = disableNavigation;

function enableNavigation() {
  var submitBtn = $('.js-btn-prev, .js-btn-next');
  submitBtn.removeClass('btn--disabled').prop('disabled', false);
}

// Global JS Function
// We're using this function in our HTML files
window.enableNavigation = enableNavigation;


$(document).on('ready ajaxComplete', function() {

  // If there's a dropzone on the page detect dragging of files and enable drag and drop on each dropzone
  if ( $('.js-doc-uploader__dropzone').length ) {

    var body = $('body');

    // Show the dropzone when dragging files (not folders or page
    // elements). The dropzone is hidden after a timer to prevent
    // flickering to occur as `dragleave` is fired constantly.
    var dragTimer;

    $(document).on('dragover', function(e) {
      var dt = e.originalEvent.dataTransfer;

      if (dt.types != null && (dt.types.indexOf ? dt.types.indexOf('Files') != -1 : dt.types.contains('application/x-moz-file'))) {
        body.addClass('is-dragging-doc');
        window.clearTimeout(dragTimer);
      }
    });

    $(document).on('dragleave', function(e) {
      dragTimer = window.setTimeout(function() {
        body.removeClass('is-dragging-doc');
      }, 25);
    });

  }

  $('.js-doc-uploader-edit-btn').off().on('click', function() {
    var doc = $(this).closest('.js-states-container');
    charlieScrollTo(doc);
    changeModuleState('is-loading', doc, $(this));
  });

}); // END ready
