/* global $ */

class DesignSystemSwitch {
  constructor ($switch) {
    this.$switch = $switch
  }

  events () {
    this.$switch.on('change', () => {
      this.handleChange()
    })
  }

  handleChange () {
    const initialState = !this.$switch.prop('checked')

    $.charlie.modal('.js-switch-modal', {
      title: this.$switch.data('modal-title'),
      content: this.$switch.data('modal-content'),
      form: this.$switch.data('modal-form'),
      type: this.$switch.data('modal-type'),
      animation: this.$switch.data('modal-animation'),
      href: this.$switch.data('modal-href'),
      close_btn: this.$switch.data('modal-close-btn'),
      confirm_btn: this.$switch.data('modal-confirm-btn'),
      remote: this.$switch.data('modal-remote'),
      method: this.$switch.data('modal-method'),
      loader: this.$switch.data('modal-loader'),
      eventName: this.$switch.data('modal-eventName')
    })

    this.$switch.data('charlie-modal').$el.on('modal:cancelling', function () {
      $('.js-switch-modal').each((index, target) => {
        const $switch = $(target)
        $switch.prop('checked', initialState)
        $switch.off('modal:cancelling')
      })
    })
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-switch-modal').each((index, target) => {
    const $switch = $(target)
    if (!$switch.data('initialized')) {
      new DesignSystemSwitch($switch).events()
      $switch.data('initialized', true)
    }
  })
})
