$(document).on('ready ajaxComplete', function () {

  function activateCheckboxesOnLoad() {
    $('.js-hidden-checkbox').each(function() {
      if ($(this).prop('checked')) {
        const checkboxId = $(this).attr('id');
        const pseudoCheckbox = $('.js-checkbox-state-handler[data-id=' + checkboxId +']');
        const checkboxParent = pseudoCheckbox.closest('.js-checkbox-state-controller');
        pseudoCheckbox.prop('checked', true);
        checkboxParent.addClass('is-checked is-selected');
      }
    });
  }

  // Only initilase if elements are present
  if ($('.js-checkbox-state-controller').length) {
    activateCheckboxesOnLoad();
  }
});


// Moved to plugin for activating checkboxes for better reuse
;(function ( $ ) {
  if (!$.charlie) {
     $.charlie = {};
  }

  $.charlie.activateCheckboxes = function (options) {
    const base = this;

    // Construct and override default options
    base.options = $.extend({}, {
      constructArray: false,
      hiddenInput: '',
      arraryOfValues: []
      }, options)

    base.init = function () {
      $('.js-checkbox-state-handler').off().on('click', function() {
        const checkbox = $(this).find('input[type="checkbox"]')
        const checkboxId = checkbox.data('id');
        const hiddenCheckbox = $('.js-hidden-checkbox#hidden_' + checkboxId);
        if (checkbox.prop('checked')) {
          hiddenCheckbox[0].setAttribute('checked', 'checked')

          // Add value to the array for the hidden input
          if (base.options.constructArray) {
            base.options.arraryOfValues.push(checkboxId);
          }

        } else {
          hiddenCheckbox[0].removeAttribute('checked')

          // Remove value from the array for the hidden input
          if (base.options.constructArray) {
            base.options.arraryOfValues.remove(checkboxId);
          }
        }

        if (base.options.constructArray) {
          base.options.hiddenInput.val(base.options.arraryOfValues);
        }
      });

      $('.js-uncheck-all').off().on('click', function() {
        const checkboxes = $('.js-hidden-checkbox');
        const pseudoCheckboxes = $('.js-checkbox-state-handler').find('input[type="checkbox"]')
        checkboxes.each(i => {
          checkboxes.get(i).removeAttribute('checked')
        })

        pseudoCheckboxes.each(i => {
          const checkbox = pseudoCheckboxes.get(i)
          if(checkbox.checked){
            checkbox.click()
          }
        })
      });
    };

    base.init();
  };

})( jQuery );
