// Setup core charlie functions and variables.
const charlie = {
  /**
   * Will contain custom JS events used throughout Charlie. The events
   * themselves get defined in ../javascript/events.
   */
  events: {},

  /**
   * Cross-browser non jQuery JS ready function, (IE9+)
   *
   * @param {Callback} fn Function to execute when the DOM is ready.
   */
  onReady(fn) {
    if (document.readyState !== 'loading') {
      fn();
    } else if (document.addEventListener) {
      document.addEventListener('DOMContentLoaded', fn);
    } else {
      document.attachEvent('onreadystatechange', () => {
        if (document.readyState !== 'loading') {
          fn();
        }
      });
    }
  },

  /**
   * Charlie's very own event handler.
   *
   * @param {String}   events  Space separated string of events to apply the
   *                           callback to, e.g. "ready scroll".
   * @param {Callback} fn      Callback to execute when the event is fired.
   * @param {Object}   element Element to listen for the event on. Defaults to `document`.
   */
  on(events, fn, element = document) {
    events.split(' ').forEach((event) => {
      if (event === 'ready') {
        // The onReady function gives us some extra value so pass any ready
        // events through to that.
        charlie.onReady(fn);
      } else {
        // Use rest parameters (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/rest_parameters)
        // to receive all parameters sent to the event callback and then the
        // confusingly similar spread syntax (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax#A_better_apply)
        // to pass them through to the supplied callback.
        element.addEventListener(event, (...params) => fn(...params));
      }
    });
  },

  onScrolledPast(offsetTop, whenPast, whenBefore) {
    const originalThingPosition = offsetTop - 80;

    $(window).on('scroll', function() {
      var thingPosition = offsetTop - 80;
      var windowPosition = $(window).scrollTop();

      if (thingPosition <= windowPosition) {
        if (thingPosition < originalThingPosition) {
          whenBefore();
        } else {
          whenPast();
        }
      } else {
        whenBefore();
      }
    });
  }
};

module.exports = charlie;
