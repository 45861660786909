//--------------------------------------------------
//  Edit company Quotes
//--------------------------------------------------

function reorderQuotes() {
  $('#quotes__table').sortable({
    items: '.js-quote',
    handle: '.js-quote__position',
    opacity: 0.6,
    update: function() {
      sendOrderToServer();
    },
    start: function() {
      $('#quote__index').addClass('sorting');
    },
    stop: function() {
      $('#quote__index').removeClass('sorting');
    },
  });
}

function sendOrderToServer() {
  var url = $('#quotes__table').data('path');
  var order = $('#quotes__table').sortable('serialize');

  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: url,
    data: order,
    success: function() {
      $('.js-quote').each(function(index) {
        $(this).find('.js-quote__position').text('Slide number ' + ( index + 1 ) );
      });
    }
  });
}

$(document).on('ready', function() {
  if ($('#quotes__table').length) {
    reorderQuotes();
  }
});
