// /* global $ */

class DesignSystemComment {
  constructor ($wrapper) {
    this.wrapper = $wrapper[0]
  }

  init () {
    this.wrapper.setAttribute('style', 'height:' + (this.wrapper.scrollHeight) + 'px;overflow-y:hidden;resize:none;')
    this.wrapper.addEventListener('input', () => {
      this.wrapper.style.height = 'auto'
      this.wrapper.style.height = (this.wrapper.scrollHeight) + 'px'
    })
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-comment').find('textarea').each((_, target) => {
    const $comments = $(target)
    if (!$comments.data('initialized')) {
      $comments.data('instance', new DesignSystemComment($comments))
      $comments.data('instance').init()
      $comments.data('initialized', true)
    }
  })
})
