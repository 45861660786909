import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {url: String}

  connect() {
    this.element[this.identifier] = this
  }

  initialize() {
    window.location.href = this.urlValue
  }
}
