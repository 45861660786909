/* global $ */

class DesignSystemCounter {
  constructor ($counter) {
    this.$counter = $counter
    this.$stateClasses = {
      complete: 'is-complete',
      'in-progress': 'is-in-progress',
      incomplete: 'is-incomplete'
    }
  }

  changeState (state) {
    this.$counter.removeClass('is-complete is-in-progress is-incomplete')
    this.$counter.addClass(this.$stateClasses[state])
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-counter').each((_, target) => {
    if (!$(target).data('initialized')) {
      $(target).data('instance', new DesignSystemCounter($(target)))
      $(target).data('initialized', true)
    }
  })
})
