/* global $ */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.form = this.element.closest("form");
    this.formData = new FormData(this.form);
    this.savedAtNote = this.element.querySelector(".input-note")
    this.url = this.form.dataset.autosaveRoute
    this.autosaveDelayAsInt = 2000

    const requestToDelay = () => this.sendRequest(this.url, this.formData);

    // Debounce the request to avoid sending too many requests.
    // The request will be sent after the time specified in autosave_target
    // has passed since the last call to the debounced function.

    this.debouncedRequest = _.debounce(requestToDelay, this.autosaveDelayAsInt);
  }
  save() {
    this.formData = new FormData(this.form);

    this.debouncedRequest(this.url, this.formData);
  }

  sendRequest(url, formData) {
    this.savedAtNote.textContent = "Saving..."
    fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
      credentials: "same-origin",
    }).then((response) => {
      response.text().then((html) => {
        var current_time = new Date()
        this.savedAtNote.textContent = "Last updated at " + current_time.toLocaleString('default', { month: 'long' }) + " " + current_time.getDate() + ", " + current_time.getFullYear() + " " + current_time.toLocaleString('default', { hour: 'numeric', minute: 'numeric' })
      });
    })
  }
}