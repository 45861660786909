/* global $ */

class DesignSystemGroupCard {
  constructor ($groupCard) {
    this.$groupCard = $groupCard
  }

  events () {
    this.setup()
  }

  setup () {
    const instance = {
      card: this.$groupCard,
      header: this.$groupCard.find('.js-group_card__header'),
      editToggles: this.$groupCard.find('.js-group_card__toggle-edit'),
      toggleEditState: function () {
        this.header.toggleClass('is-editing')
      }
    }

    instance.editToggles.on('click', function () {
      instance.toggleEditState()
    })

    this.$groupCard.data('initialized', true)

    return instance
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-group_card').each((index, target) => {
    const $groupCard = $(target)
    if (!$groupCard.data('initialized')) {
      new DesignSystemGroupCard($groupCard).events()
    }
  })
})
