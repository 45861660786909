export default class DesignSystemShowPassword {
  constructor ($button) {
    this.$button = $button
    this.$input = $button.prev().find('.js-input')
    this.originalText = $button.text()
  }

  bindEvents () {
    this.$button.on('click', (e) => {
      e.preventDefault()

      if (this.$input.attr('type') === 'password') {
        this.$input.attr('type', 'text')
        this.$button.text('Hide password')
      } else {
        this.$input.attr('type', 'password')
        this.$button.text(this.originalText)
      }
    })
  }
}
