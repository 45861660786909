export default class SortableQuestions {
  init($container) {
    if ($container.length < 1) {
      return;
    }

    // Use jQuery UI's Sortable interaction.
    $container.sortable({
      // When the order changes a hidden form element containing the position
      // for each question is updated behind the scenes. When the step is then
      // navigated away from the new positions are saved.
      update: (event) => {
        const $updatedContainer = $(event.target);
        let position = 0;

        $updatedContainer.children('li').each((_, element) => {
          $(element).find('.js-question-position').val(position);
          position += 1;
        });
      },
    });
  }
}
