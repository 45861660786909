function checklist_item_delete(item) {
  item.toggleClass('v--deleted');
  var input = item.find('.js-checklist-item__deleted');

  if ( item.hasClass('v--deleted') ) {
    input.val('true');
  } else {
    input.val('false');
  }
}

function checklist_item_add(item) {
  var checklist = item.prev('.js-note-card-checklist');
  var number_of_items = checklist.children().length;

  var checklist_item = [
    '<div class=\"c-checklist-item js-checklist-item\" data-checklist-item-id=\"'+ number_of_items +'\">',
    '  <label class=\"c-checkbox__label\">',
    '    <input name=\"team_member_note[check_list][' + number_of_items + '][checked]\" type=\"hidden\" value=\"0\">',
    '    <input class=\"boolean optional c-checkbox\" type=\"checkbox\" value=\"1\" name=\"team_member_note[check_list[' + number_of_items + '][checked]\" id=\"team_member_note_check_list_' + number_of_items + '_checked\">',
    '    <svg class=\"c-checkbox__icon\" viewBox=\"0 0 50 50\">',
    '      <title>Checkbox Icon<\/title>',
    '      <rect class=\"fill stroke stroke-black checkbox__bg\" x=\"9.8\" y=\"9.8\" width=\"30.4\" height=\"30.4\"><\/rect>',
    '      <polyline class=\"stroke stroke-current checkbox__check fill-none\" points=\"33.1,18 23.5,31.3 16.8,26.5\"><\/polyline>',
    '    <\/svg>',
    '  <\/label>',
    '  <input class=\"string optional c-input v--charcoal-115\" placeholder=\"Name checkbox\" type=\"text\" value=\"\" name=\"team_member_note[check_list][' + number_of_items + '][content]\" id=\"team_member_note_check_list_' + number_of_items + '_content\">',
    '  <div class=\"form-group hidden team_member_note_check_list_deleted filled\">',
    '    <input class=\"form-control hidden js-checklist-item__deleted\" name=\"team_member_note[check_list][' + number_of_items + '][deleted]\" id=\"team_member_note_check_list_' + number_of_items + '_deleted\" value=\"false\" type=\"hidden\">',
    '  <\/div>',
    '  <button type=\"button\" class=\"c-checklist-item__delete-btn js-checklist-item__delete-btn\">',
    '    <svg class=\"c-checklist-item__delete-icon\" viewBox=\"0 0 50 50\">',
    '      <title>Delete icon<\/title>',
    '      <path d=\"M14.6 40.2c.2 1.4 1.4 2.5 2.8 2.5h15.2c1.4 0 2.7-1.1 2.8-2.5l2.3-20.3H12.3l2.3 20.3zM37.7 12.3h-5.1l-1.9-5.1H19.3l-1.9 5.1h-5.1c-2.5 0-2.5 5.1-2.5 5.1h30.5c-.1 0-.1-5.1-2.6-5.1zm-17.8 0l1.3-2.5h7.6l1.3 2.5H19.9z\"><\/path>',
    '    <\/svg>',
    '  <\/button>',
    '<\/div>'
  ].join("\n");

  // Append new item to checklist
  checklist.append(checklist_item);

  // Setup delete button
  var new_item = item.prev('.js-note-card-checklist').children().last()
  new_item.find('.js-checklist-item__delete-btn').on('click', function() {
    checklist_item_delete( new_item );
  })
}

$(document).on('ready', function() {

  $('.js-checklist-item__delete-btn').on('click', function() {
    checklist_item_delete( $(this).closest('.js-checklist-item') );
  });

  $('.js-add-checklist-item').on('click', function() {
    checklist_item_add( $(this) );
  });

});
