/* global $ */

class DesignSystemTableRow {
  constructor ($tableRow) {
    this.$tableRow = $tableRow[0]

    this.initializeCharlieFunctions()
  }

  init () {
    // Suspect this deleteButton is no longer used - have placed Tombstone to test
    const deleteButton = this.$tableRow.querySelector('.js-delete-table-row')
    if (deleteButton) {
      deleteButton.addEventListener('click', () => {
          $.charlie.removeTableRow(this.$tableRow)
        });
    }
  }

  initializeCharlieFunctions () {
    if (!$.charlie) {
      $.charlie = {};
    }

    if (!$.charlie.replaceTableRow) {

      // used by bulk_actions.js.erb when updating row(s) from table
      $.charlie.replaceTableRow = ($target, $row) => {
        var $table = $target.closest('.js-table')[0]
        var id = $target.attr('id')
        $target.replaceWith($row)

        $('#' + id).addClass('is-changing')

        setTimeout(function () {
          $('#' + id).removeClass('is-changing')
          $table.data['table'].updateTable(id)
        }, 1500)
      }
    }

    // used when removing item from table
    if (!$.charlie.removeTableRow) {
      $.charlie.removeTableRow = ($row) => {
        var $table = $row.closest('.js-table')[0]
        $row.remove()
        $table.data['table'].updateTable(null)
      }
    }

    // Suspect this is no longer used - have placed Tombstones:
    if (!$.charlie.newTableRow) {
      $.charlie.newTableRow = ($table, $row) => {
        var $tableBody = $table.find('.js-table-body')
        $tableBody.prepend($row)
        $table.data('table').updateTable()
      }
    }
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-table-row').each((_, target) => {
    const $tableRow = $(target)

    if (!$tableRow.data('initialized')) {
      $tableRow.data('instance', new DesignSystemTableRow($tableRow))
      // $tableRow.data('instance').init()
      $tableRow.data('initialized', true)
    }
  })
});
