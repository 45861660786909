/* global $ */

class DesignSystemChecklistTaskGroup {
  constructor (checklistTaskGroup) {
    this.checklistTaskGroupNode = checklistTaskGroup
    this.checklistTaskGroup = checklistTaskGroup[0]
  }

  init () {
    this.activateShowCompletedTasksButton()
    this.activateEditButtonOnMobile()
    this.reorderTasks()
  }

  updateChecklistTaskPosition (task) {
    const actionUrl = this.checklistTaskGroup.getAttribute('data-action-url')
    if ( actionUrl ) {
      const itemsToSort = task.closest('.js-checklist_task_group__tasks--reorderable')
      const array_of_items = itemsToSort.sortable().sortable('toArray', { attribute: 'data-id' });
      $.ajax({
        type: "PATCH",
        dataType: "script",
        url: actionUrl,
        data: {
          checklist: {
            order: array_of_items
          }
        }
      });
    } else {
      console.warn('Could not create update path');
    }
  }

  activateShowCompletedTasksButton () {
    const showTaskButton = this.checklistTaskGroup.querySelector('.js-show-tasks')
    if(showTaskButton) {
      if ( !showTaskButton.data || !showTaskButton.data.initialized ) { // Don't initialize events for buttons that have events
        showTaskButton.addEventListener('click', () => {
          this.checklistTaskGroup.classList.remove('has-success')
        })
        showTaskButton.data = { initialized: true }
      }
    }
  }

  activateEditButtonOnMobile () {
    const editButton = this.checklistTaskGroup.querySelector('.js-checklist_task_group-edit-on-mobile')
    if (editButton) {
      if ( !editButton.data || !editButton.data.initialized ) { // Don't initialize events for buttons that have events
        editButton.addEventListener('click', () => {
          if(this.checklistTaskGroup.classList.contains('is-editing')){
            this.checklistTaskGroup.querySelectorAll('.c-checklist_task__actions').forEach((actions) => {
              actions.style.display = 'none'
            })
            this.checklistTaskGroup.classList.remove('is-editing')
          } else {
            this.checklistTaskGroup.querySelectorAll('.c-checklist_task__actions').forEach((actions) => {
              actions.style.display = 'block'
            })
            this.checklistTaskGroup.classList.add('is-editing')
          }
        })
        editButton.data = { initialized: true }
      }
    }
  }

  reorderTasks () {
      const tasks = this.checklistTaskGroup.querySelectorAll('.js-checklist-task')
      let time_out_id

      if ( tasks.length > 0 ) {
        this.checklistTaskGroupNode.sortable({
          items: ".js-checklist-task",
          opacity: 0.6,
          update: (event, ui) => {
            // Ignore the previous request if you trigger a new one
            if ( time_out_id != 0 ) { clearTimeout(time_out_id); }

            time_out_id = setTimeout(() => {
              time_out_id = 0;
              this.updateChecklistTaskPosition( $(ui.item) );
            }, 2000);
          }
        })
      }
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-checklist_task_group').each((index, target) => {
    const $checklistTaskGroup = $(target)
    if (!$checklistTaskGroup.data('initialized')) {
      new DesignSystemChecklistTaskGroup($checklistTaskGroup)
      new DesignSystemChecklistTaskGroup($checklistTaskGroup).init()
      $checklistTaskGroup.data('initialized', true)
    }
  })
})
