function getURLParam(name) {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
}

function getDomain(){
  if(document.domain.length){
    var parts = document.domain.replace(/^(www\.)/,"").split('.');

    //is there a subdomain?
    while(parts.length > 2){
        //removing it from our array
        var subdomain = parts.shift();
    }

    //getting the remaining 2 elements
    var domain = parts.join('.');

    return domain.replace(/(^\.*)|(\.*$)/g, "");
  }
  return '';
}

function getExpirationDays(){
  return 3652; // ten years (+2 leap days)
}

// Sets a cookie called name with the value passed in args
function setCookie(name, value) {
  Cookies.set(name, value, {
    expires: expiration,
    path: '/',
    domain: domain,
    secure: false
  });
}

// Sets a cookie called name with the string in stored in param
function setUTMCookie(name, param) {
  var value = getURLParam(param);
  if(value !== null) {
    setCookie(name, value)
  }
}

$(function() {
  domain = getDomain();
  expiration = getExpirationDays();
  referral_source = getURLParam('refSource');
  referral_code = getURLParam('referralCode');

  // Referral source
  if ((referral_source !== null) && (referral_code !== null)) {
    setCookie('utm_source', 'referral');
    setCookie('utm_medium', referral_source);
    setCookie('utm_campaign', referral_code);
    setCookie('utm_content', '');
    setCookie('utm_term', '');

  } else { // Different source, e.g. Facebook ads
    setUTMCookie('utm_source', 'utm_source');
    setUTMCookie('utm_medium', 'utm_medium');
    setUTMCookie('utm_campaign', 'utm_campaign');
    setUTMCookie('utm_content', 'utm_content');
    setUTMCookie('utm_term', 'utm_term');

  }
});
