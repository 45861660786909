$(document).on('ready ajaxComplete', function() {
  // Animate and hide alerts
  $('.alert-info').delay(4000).slideUp(500);
});

// This is used on notifications page
function activateLoadMoreButton(element) {
  $(element).on('click', function(event) {
    event.preventDefault();
    $.ajax({
      type: "GET",
      url: $(this).find('.next_page').attr('href'),
      dataType: 'script'
    });
  });
}

// Global JS Function
// We're using this function in our HTML files
window.activateLoadMoreButton = activateLoadMoreButton;


$(document).on('ready ajaxComplete', function() {

  // Change collapsible card state
  $('.js-toggle-expand').off().on('click', function() {
    var $stateContainer = $(this).closest('.js-states-container');

    if ($stateContainer.hasClass('is-collapsed')) {
      $stateContainer.addClass('is-expanded');
      $stateContainer.removeClass('is-collapsed');
    } else {
      $stateContainer.addClass('is-collapsed');
      $stateContainer.removeClass('is-expanded');
    }

    $(document).trigger('charlieLayoutUpdated');
  });
});

$(document).on('ready ajaxComplete', function() {

  // Change tabled visibility
  $('.js-change-visibility').off().on('click', function() {

    var state = $(this).val(),
        data  = $(this).data('visibility');

    var hideTarget = $('.js-hide-if-checked[data-visibility="' + data + '"]');
    var showTarget = $('.js-show-if-checked[data-visibility="' + data + '"]');

    if (state === 'true') {
      showTarget.addClass('is-hidden');
      hideTarget.removeClass('is-hidden');
      $(this).val('false');

    } else {
      showTarget.removeClass('is-hidden');
      hideTarget.addClass('is-hidden');
      $(this).val('true');
    }
  });
});

$(document).on('ready', function() {
  var addClassToTargetWhenContextScrolled = function(context, target) {
    if (context.scrollTop() > 0) {
      target.addClass('is-scrolled');
    } else {
      target.removeClass('is-scrolled');
    }
  };

  addClassToTargetWhenContextScrolled($(this), $('body'));

  $(window).on('scroll', function() {
    addClassToTargetWhenContextScrolled($(this), $('body'));
  });
});
