/* global charlie */

import Exclusions from './exclusions';
import Reminders from './reminders';
import SchemaLink from './schema-link';
import SortableQuestions from './sortable-questions';

export default class Reviews {
  /**
   * Set-up events. This will need to be called after every step progression to
   * ensure events are set up properly.
   */
  init() {
    this.schemaLink = this.schemaLink || new SchemaLink();
    this.schemaLink.init();

    this.exclusions = this.exclusions || new Exclusions();
    this.exclusions.init();

    this.reminders = this.reminders || new Reminders();
    this.reminders.init();

    this.sortableQuestions = this.sortableQuestions || new SortableQuestions();
    this.sortableQuestions.init($('.js-reviewee-questions-list'));
    this.sortableQuestions.init($('.js-reviewer-questions-list'));
  }
}

const reviews = new Reviews();

// When the onboardingStepChange event gets fired we need to bind events to
// elements on the new step. onReady won't be triggered as it's only a partial
// DOM change, not a full page reload.
charlie.on('ready charlie:flow-step-change', () => reviews.init());
