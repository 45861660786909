$(document).on('ready ajaxComplete', function() {

  // forms and onboarding
  $('.js-toggle-section-checkbox').on('change', function() {
    var hideContent = $(this).is(':checked');
    var sectionName = $(this).data('type');
    var target = $('.js-hidden-section[data-section-name="' + sectionName + '"]');

    $.charlie.toggleSection(hideContent, target);
  });


  // Visa
  function hideVisaFields() {
    if ($('#team_member_visa_currently_applying').is(':checked')) {
      $('.js-visa-upload').hide();
    } else {
      $('.js-visa-upload').show();
    }
  }

  hideVisaFields();

  $('#team_member_visa_currently_applying').on('change', function() {
    hideVisaFields();
  });

});
