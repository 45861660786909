//--------------------------------------------------
//  Time off team list
//--------------------------------------------------

function initializeTimeOffList() {

  function sendAttributes(id, unlimited_leave, leave_limit_adjustment, imported_days_approved) {
    $.ajax({
      type: "POST",
      url: '/people/' + id + '/update_leave_settings',
      data: { 'team_member' : { 'unlimited_leave' : unlimited_leave,
                                'leave_limit_adjustment' : leave_limit_adjustment,
                                'imported_days_approved' : imported_days_approved }
            }
    });
  }

  function changeAttribute(attribute, up_or_down) {
    // console.log('changed');

    value = parseFloat(attribute.text());
    if (up_or_down == "+") {
      value += 0.5;
    } else {
      value -= 0.5;
    }
    if (value > 0) {
      value = ("+" + value);
    }
    attribute.text(value);
  }

  function toggleInfinity(element) {
    var limited,
        unlimited,
        hidden_field;

    if (element.attr('id') == 'company-unlimited-leave') {
      limited = $('.company-leave-limit');
      unlimited = $('.unlimited-company-leave-limit');
      hidden_field = $('#leave_preferences_unlimited');

    } else {
      limited = element.parents('.js-content-table-row').find('.js-leave-limit-value');
      unlimited = element.parents('.js-content-table-row').find('.js-unlimited-leave-limit-value');
      hidden_field = element;
    }

    if (element.is(":checked")) {
      hidden_field.val("1");
      limited.addClass('not-editing not-open--mobile');
      unlimited.removeClass('not-editing not-open--mobile');

    } else {
      hidden_field.val("0");
      limited.removeClass('not-editing not-open--mobile');
      unlimited.addClass('not-editing not-open--mobile');
    }
  }



  $(document).on('ready ajaxComplete', function() {
    var saveTimeOff  = $('.js-save-table-row');

    saveTimeOff.off().on('click', function(e) {
      var $this = $(this),
          item  = $this.closest('.js-content-table-row'),
          card  = item.closest('.js-team-table');

      changeModuleState('is-loading', item, $this);

      var id = item.data("team-member-id");
      var unlimited_leave = item.find(".js-unlimited-leave").val();
      var leave_limit_adjustment = item.find(".js-leave-limit-adjustment").text();
      var imported_days_approved = item.find(".js-imported-days-approved").text();

      sendAttributes(id, unlimited_leave, leave_limit_adjustment, imported_days_approved);
    });


    $(document).keyup(function(e) { //remove states if user clicks escape
      if (e.keyCode == 27) {
        resetModuleState();
      }
    });


    $('.js-decrease-leave-limit-adjustment').off().on('click', function(){
      var leave_limit_adjustment = $(this).parent().find('.js-leave-limit-adjustment');
      changeAttribute(leave_limit_adjustment, "-");
    });

    $('.js-increase-leave-limit-adjustment').off().on('click', function(){
      var leave_limit_adjustment = $(this).parent().find('.js-leave-limit-adjustment');
      changeAttribute(leave_limit_adjustment, "+");
    });

    $('.js-decrease-imported-days-approved').off().on('click', function(){
      var imported_days_approved = $(this).parent().find('.js-imported-days-approved');
      changeAttribute(imported_days_approved, "-");
    });

    $('.js-increase-imported-days-approved').off().on('click', function(){
      var imported_days_approved = $(this).parent().find('.js-imported-days-approved');
      changeAttribute(imported_days_approved, "+");
    });

    $('.js-unlimited-leave').on('click', function() {
      toggleInfinity($(this));
    });
  }); // END document ready

}

// Global JS Function
// We're using this function in our HTML files
window.initializeTimeOffList = initializeTimeOffList;
