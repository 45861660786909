$(document).on('ready ajaxComplete', function() {
  var cells = $('.js-cal-cell');
  var snapshots = $('.js-cal-snapshot');

  cells.on('click', function(){
    var cell = $(this);
    var snapshot = $('.js-cal-snapshot[data-id="' + cell.data('id') + '"]');
    var events = $('.js-cal-event');
    var scopedEvents = cell.find(events);

    events.removeClass('is-active');
    scopedEvents.each(function() {
      var uuid = $(this).data('uuid');
      var eventDuration = $('.js-cal-event[data-uuid="' + uuid + '"]');

      eventDuration.addClass('is-active');
    });

    snapshots.not(snapshot).removeClass('is-active');
    snapshot.addClass('is-active');
    cells.not(cell).removeClass('is-active');
    cell.addClass('is-active');

    selectedDate = cell.data('id');
    uri = $('.js-book-time-off').attr('href');
    $('.js-book-time-off').attr('href', updateUrlParameter(uri, 'start_date', selectedDate));
  });

  function updateUrlParameter(uri, key, value) {
    var i = uri.indexOf('#');
    var hash = i === -1 ? ''  : uri.substr(i);
         uri = i === -1 ? uri : uri.substr(0, i);
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        uri = uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
        uri = uri + separator + key + "=" + value;
    }
    return uri + hash;
  }
});
