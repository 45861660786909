// TODO: 3 June 2020, DG: Don't think this is being used. Can it be removed?

function calendar_integration_select_timezone () {
  $timezones = $("#timezone_form_timezone");
  $companyCalendar = $("#calendar-integration__company");

  COMPANY_CALENDAR_LINK = $companyCalendar.val();

  $timezones.on("change", function() {
    timezone = $(this).val();

    $companyCalendar.val(COMPANY_CALENDAR_LINK + '&timezone=' + timezone);

    $('.time-off__integration-select').find('label').removeClass('u-bg--coolgray').addClass('u-bg--success');
  });
}

$(document).on('ready', function() {
  if ( $("#timezone_form_timezone").length ) {
    calendar_integration_select_timezone();
  };
});
