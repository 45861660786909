// /* global $ */

class DesignSystemBulkActions {
  constructor ($wrapper) {
    this.wrapper = $wrapper[0]

    const bulkActions = {
      fixBulkActions: function() {
        $wrapper[0].classList.add('is-fixed')
      },
      unfixBulkActions: function() {
        $wrapper[0].classList.remove('is-fixed')
      },
      activateBulkActions: function() {
        $wrapper[0].classList.add('is-active')
        $wrapper[0].classList.remove('is-inactive')
      },
      deactivateBulkActions: function() {
        $wrapper[0].classList.remove('is-active')
        $wrapper[0].classList.add('is-inactive')
        const avatarGroupData = $wrapper[0].querySelector('.js-avatar_group').data
        avatarGroupData && avatarGroupData['avatar-group'].removeAllAvatars()
      },
      updateTitle: function(title) {
        const target = $wrapper[0].querySelector('.js-bulk-actions-title')
        target.innerHTML = title
      },
    };

    this.wrapper.data = {'bulk-actions': bulkActions}

    charlie.onScrolledPast( // eslint-disable-line no-undef
      this.wrapper.offsetTop,
      bulkActions.fixBulkActions,
      bulkActions.unfixBulkActions
    );
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-bulk-actions').each((_, target) => {
    const $bulkActions = $(target)
    if (!$bulkActions.data('initialized')) {
      $bulkActions.data('instance', new DesignSystemBulkActions($bulkActions))
      $bulkActions.data('initialized', true)
    }
  })
})
