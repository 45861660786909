/* global $ */

class DesignSystemAccordion {
  constructor ($accordion) {
    this.$accordion = $accordion
  }

  events () {
    this.$accordion.children('.js-accordion-bar').on('click', (e) => {
      const clickableChildren = this.$accordion.find('.c-button, .js-avatar')

      // Prevent opening of the accordion if you click on a button inside the accordion bar.
      if (!clickableChildren.is(e.target) && !clickableChildren.has(e.target).length) {
        e.preventDefault()

        if (this.$accordion.hasClass('is-open')) this.close()
        else this.open()
      }
    })
  }

  open () {
    if (this.$accordion.data('openable')) {
      this.$accordion.addClass('is-open')
      this.$accordion.removeClass('is-closed')

      this.$accordion.attr('aria-expanded', true)

      this.$accordion.children('.js-accordion-content').slideDown(100, () => {
        this.$accordion.trigger('accordionOpen')
      })

      if (this.$accordion.attr('remote-link') && !this.$accordion.attr('remote-loaded')) {
        this.handleRemote()
      }
    }
  }

  close () {
    this.$accordion.removeClass('is-open')

    this.$accordion.attr('aria-expanded', false)

    if (this.$accordion.data('openable')) {
      this.$accordion.addClass('is-closed')
    }

    this.$accordion.children('.js-accordion-content').slideUp(100)
  }

  handleRemote () {
    $.ajax({
      type: 'GET',
      dataType: 'html',
      url: this.$accordion.data('remote-link'),
      beforeSend: () => {
        $('.js-accordion-loader', this.$accordion).addClass('is-loading')
      },
      complete: () => {
        $('.js-accordion-loader', this.$accordion).removeClass('is-loading')
      },
      success: (data) => {
        this.$accordion.attr('remote-loaded', true)
        $('.js-accordion-content-drop', this.$accordion).replaceWith(data)
      },
      error: () => {
        $('.js-accordion-content-error').removeClass('is-hidden')
      }
    })
  }
}

$(document).on('ready ajaxComplete turbo:frame-render', () => {
  $('.js-accordion').each((index, target) => {
    const $accordion = $(target)
    if (!$accordion.data('initialized')) {
      const klass = new DesignSystemAccordion($accordion)
      klass.events()
      $accordion.data('initialized', true)

      if ($accordion.data('pre-open')) klass.open()
      $accordion.data('instance', klass)
    }
  })
})
