/* global $, charlie */

class DesignSystemTooltip {
  constructor (tooltip, control) {
    this.tooltip = tooltip[0]
    this.control = control
    this.visibleOnLoad = this.tooltip.classList.contains('js-visible-on-load')
  }

  init () {
    this.initialisePopper()
  }

  initialisePopper () {
    new Popper(this.tooltip, this.visibleOnLoad, this.control).init()
  }
}

charlie.onReady(() => {
  $(document).on('ready ajaxComplete', () => {
    $('.js-tooltip').each((index, target) => {
      const $tooltip = $(target)
      if (!$tooltip.data('initialized')) {
        new DesignSystemTooltip($tooltip).init()
        $tooltip.data('initialized', true)
      }
    })
  })
})

export { DesignSystemTooltip }
