/**
 * Handles the reviewee exclusions selector, specifically updating visible
 * avatars as the selection changes.
 */
export default class Exclusions {
  init() {
    if ($('.js-review-avatars').length > 0) {
      this.initExcludedAvatars();
    }

    if ($('.js-exclusion-toggle').length > 0) {
      this.initToggle();
    }
  }

  /**
   * Hide avatars when they are excluded from a review.
   */
  initExcludedAvatars() {
    // Handle when the option is selected on the first pass through. This will
    // force only a specific number of avatars to be shown when the option is
    // first displayed.
    $('.js-radio-group').change(() => this.handleChange());

    // Note! Deliberately not setting the initialized data attribute here as
    // doing so can cause the component's JS not to add it's events, rendering
    // the Multi Select broken.
    $('.js-exclusion-selector .js-checkbox-input').change(() => this.handleChange());

    // Handle when the user returns to the step and has previously selected
    // options. As the option will already be open the change event above won't
    // get fired so we need to do it manually.
    //
    // We also need to use a timeout to force this to happen after the
    // component's JS. If left to it's own devices this will run before the
    // select component has done what it's need to and so avatars won't be
    // hidden. Forcing this to wait ensures it's finished working and the page
    // will be in an expected state.
    //
    // This is seriously hacky :( when everything is moved over we should be
    // able to do away with it, though.
    setTimeout(() => this.handleChange(), 100);
  }

  /**
   * Handle selection changes in the exclusions selector. This will hide avatars
   * from Team Members that have been selected and update the count of Team
   * Members included in the review.
   */
  handleChange() {
    const $avatarGroup = $('.js-review-avatars');
    const selected = [];

    // Show all so any previously hidden avatars are available if necessary.
    $avatarGroup.find('.is-hidden').removeClass('is-hidden');

    // Build an array of selected team members who are being excluded.
    $('.js-exclusions .js-ms-selected-options .e-tag')
      .each((_, element) => selected.push($(element).data('value')));

    // Hide exclusions, update the total reviewees and hide avatars so that the
    // limit of avatars displayed isn't exceeded.
    this.hideAvatarsById($avatarGroup, selected);

    $('.js-avatar_group-text')
      .html(`${$avatarGroup.find('.js-avatar').not('.is-hidden').length} total`);

    this.limitAvatars($avatarGroup);
  }

  /**
   * Receives an array of IDs and hides avatars if they match.
   *
   * @param {Object} $avatarGroup Containing object to search for matches in.
   * @param {Array}  IDs
   */
  hideAvatarsById($avatarGroup, IDs) {
    const selectors = [];
    IDs.forEach(ID => selectors.push(`.js-avatar[data-member="${ID}"]`));
    $avatarGroup.find(selectors.join(',')).addClass('is-hidden');
  }

  /**
   * Hide avatars so that the limit isn't passed.
   *
   * @param {Object} $avatarGroup
   */
  limitAvatars($avatarGroup) {
    const $visibleAvatars = $avatarGroup.find('.js-avatar').not('.is-hidden');
    const limit = $avatarGroup.data('limit');
    const selectors = [];

    if ($visibleAvatars.length > limit) {
      $visibleAvatars.slice(limit)
        .each((_, element) => {
          selectors.push(`.js-avatar[data-member="${$(element).data('member')}"]`);
        });

      $avatarGroup.find(selectors.join(',')).addClass('is-hidden');
    }
  }

  /**
   * Shows the exclusion selector on the reviewee step of the launch flow.
   */
  initToggle() {
    $('.js-exclusion-selector').hide();

    $('.js-exclusion-toggle').click((event) => {
      event.preventDefault();

      if ($('.js-exclusion-selector').is(':hidden')) {
        $('.js-exclusion-selector').addClass('u-padding--t-25').show();
      } else {
        $('.js-exclusion-selector').hide();
      }
    });
  }
}
