// ------------------------------------------------\\
// Charlie animation functions
// ------------------------------------------------\\
const tombstone = require('./tombstone');

function animateOutContentOf(element) {
  tombstone.place('core/animations.js#animateOutContentOf:6'); // Tombstone places on 2020-07-29 by DG.

  $(element)
    .find('.will-slide-fade-in')
    .addClass('will-slide-fade-out')
    .removeClass('will-slide-fade-in');

  $(element)
    .find('.will-fade-in')
    .addClass('will-fade-out')
    .removeClass('will-fade-in');

  $(element)
    .find('.will-slide-in')
    .addClass('will-slide-out')
    .removeClass('will-slide-in');
}

function loadComponentsWithDelay(components, delayIncrement) {
  var delay = 0;

  $(components).each(function() {
    var component = $(this);

    if (component.hasClass('go')) {
      return;
    } else {
      delay += delayIncrement;
      setTimeout(function() {
        component.addClass('go');
      }, delay);
    }
  });
}

function removeComponentsWithDelay(components, delayIncrement) {
  var delay = 0;

  $(components.get().reverse()).each(function() {
    var component = $(this);

    if (component.hasClass('go')) {
      delay += delayIncrement;
      setTimeout(function() {
        component.removeClass('go');
      }, delay);
    }
  });
}

$(document).on('ready', function() {
  $(window).on('load', function() {
    if (window.location.hash) {
      charlieScrollTo($(window.location.hash), 200);
    }
  });
});

$(document).on('ready ajaxComplete', function() {

  // make inline links scroll instead of jump
  $('.js-scroll-to').on('click', function() {
    var sectionId = $.attr(this, 'href');
    var section = $(sectionId);

    charlieScrollTo( section );
  });

  // Dashboard card animations
  if ($('.u-anim--slide-up').length) {
    loadComponentsWithDelay('.u-anim--slide-up', 100);
  }

  // General purpose animations
  if ($('.slide-in').length) { $('.slide-in').addClass('go'); }
  if ($('.slide-in2').length) { $('.slide-in2').addClass('go'); }

  // all submit buttons
  $('form').on('submit', function() {
    var $this = $(this);
    var loadBtn = $this.find('.js-submit-btn');
    var thisBtn = $this.find('input[type=submit]');
    var buttonText = thisBtn.val();

    thisBtn.prop('disabled', 'true');
    thisBtn.data('initial-value', buttonText);

    // IF this form is in a card that should trigger loading state on save
    if ( $this.hasClass('js-loading-on-save') ) {
      var module = $this.closest('.js-states-container');
      changeModuleState('is-loading', module, false);

    // IF this form has a button that can change to a loader
    } else if ( loadBtn.length ) {
      if (loadBtn.hasClass('js-dont-load')) {

        // Do nothing
      } else {
        loadBtn.addClass('is-loading');
      }

    // Fallback incase a default loading state is not set
    } else {
      thisBtn.val('Loading');
    }
  });

}); // ready ajaxcomplete
