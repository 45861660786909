/* global $ */

class DesignSystemSelect {
  constructor ($select) {
    this.$select = $select
    this.$wrapper = $select.closest('.js-select__wrapper')

    if (this.$wrapper.hasClass('js-select-toggle-content')) {
      this.$select.on('change', () => {
        this.toggleContent()
      })
    }
  }

  addValue (value, name) {
    this.$select.append(`<option value='${value}'>${name}</option>`)
    this.setValue(value)
  }

  setValue (value) {
    this.$select.val(value)
  }

  toggleDisabled () {
    const currentStatus = this.$select.prop('disabled')

    this.$select.prop('disabled', !currentStatus)
    this.$wrapper.toggleClass('is-disabled')
  }

  toggleContent () {
    const selectedOption = this.$select.val()

    $('.js-select-toggle-target').addClass('is-hidden')

    $(`.js-select-toggle-target[data-option=${selectedOption}]`).removeClass('is-hidden')
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-select__input-field').each((_, target) => {
    if (!$(target).data('initialized')) {
      $(target).data('select', new DesignSystemSelect($(target)))
      $(target).data('initialized', true)
    }
  })
})
