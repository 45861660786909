/* global $ */

class DesignSystemTable {
  constructor (table) {
    this.table = table[0]

    const selectableRows = this.table.querySelector(".js-selectable-rows")
    if(selectableRows){
      this.setCheckboxes()
      this.totalNumberOfCheckboxes = this.checkboxes.length
      this.bulkActions = document.querySelector('.js-bulk-actions')
      this.avatarGroup = this.bulkActions.querySelector('.js-avatar_group')

      const tableObj = {
        updateTable: (rowId) => {
          this.updateTable(rowId)
        }
      }

      this.table.data = {'table': tableObj}

      this.bindSelectAllEvents()
      this.setSelectAllCheckboxState()
    }
  }

  // sets checkboxes on page load and on any row(s) updated
  setCheckboxes () {
    this.selectAllCheckbox = this.table.querySelector('.js-checkbox-input-select-all')
    this.checkboxes = Array.from(this.table.querySelectorAll('.js-checkbox-input')).filter((checkbox) => {
      return checkbox !== this.selectAllCheckbox
    })
  }

  // Adds the event listener for click on the checkbox(es) of the row(s) that was/were just changed (line manager changed or office changed or added to team or removed from team etc) or deleted
  updateTable (rowId) {
    this.setCheckboxes()
    this.selectAllUnSelected()

    // if row was updated and not deleted
    if(rowId) {
      const row = document.getElementById(rowId)
      const checkbox = row.querySelector('.js-checkbox-input')
      this.handleCheckboxListener(checkbox)
    }
  }

  // sets the event listener both on page load and on any row(s) updated
  handleCheckboxListener (checkbox) {
    checkbox.removeEventListener('click', null)
    checkbox.addEventListener('click', () => {
      this.setSelectAllCheckboxState()
      this.storeSelectedTableRowIds(checkbox)
    })
  }

  bindSelectAllEvents () {
    this.table.setAttribute('selected-rows', '[]')

    this.selectAllCheckbox.removeEventListener('click', null)
    this.selectAllCheckbox.addEventListener('click', () => {
      if ( this.selectAllCheckbox.checked ) {
        this.checkAllCheckboxes()
      } else {
        this.uncheckAllCheckboxes()
      }
    })

    this.checkboxes.map((checkbox) => {
      this.handleCheckboxListener(checkbox)
    })
  }

  howManyCheckboxesAreChecked() {
    let totalNumberOfCheckedCheckboxes = 0

    this.checkboxes.map((checkbox) => {
      if (checkbox.checked) {
        totalNumberOfCheckedCheckboxes += 1
      }
    })

    if (totalNumberOfCheckedCheckboxes === 0) {
      return 'none'
    } else {
      if (this.totalNumberOfCheckboxes === totalNumberOfCheckedCheckboxes) {
        return 'all'
      } else if (totalNumberOfCheckedCheckboxes > 0) {
        return 'some'
      } else {
        return 'none'
      }
    }
  }

  selectAllUnSelected () {
    this.selectAllCheckbox.checked = false
    this.selectAllCheckbox.indeterminate = false
  }

  setSelectAllCheckboxState() {
    const howManySelected = this.howManyCheckboxesAreChecked()

    switch (howManySelected) {
      case 'all':
        this.selectAllCheckbox.checked = true
        this.selectAllCheckbox.indeterminate = false
        this.selectAllSelected()
        break
      case 'some':
        this.selectAllCheckbox.checked = true
        this.selectAllCheckbox.indeterminate = true
        break
      case 'none':
        this.selectAllUnSelected()
        break
      default:
        this.selectAllUnSelected()
        break
    }
  }

  checkAllCheckboxes() {
    this.checkboxes.map((checkbox) => {
      checkbox.checked = true
      this.storeSelectedTableRowIds(checkbox)
    })
  }

  uncheckAllCheckboxes() {
    this.checkboxes.map((checkbox) => {
      checkbox.checked = false
      this.storeSelectedTableRowIds(checkbox)
    })
  }

  getTableRowId(checkbox) {
    return checkbox.closest('.js-table-row').getAttribute('id')
  }

  getTableRowAvatarUrl(checkbox) {
    return checkbox.closest('.js-table-row').querySelector('.js-avatar').style.backgroundImage
  }

  storeSelectedTableRowIds(checkbox) {
    const ID = this.getTableRowId(checkbox)
    const avatarURL = this.getTableRowAvatarUrl(checkbox)
    let storedIds = JSON.parse(this.table.getAttribute('selected-rows'))

    if (checkbox.checked) {
      if (!storedIds.includes(ID)) {
      storedIds.push(ID)
      }
      this.avatarGroup.data['avatar-group'].addAvatar(ID, avatarURL)
      this.bulkActions.data['bulk-actions'].activateBulkActions()
    } else {
      storedIds = storedIds.filter( id => {
        if(id !== ID){
          return id
        }
      })

      this.avatarGroup.data['avatar-group'].removeAvatar(ID)
      if (this.avatarGroup.data['avatar-group'].getCount() === 0) {
        bulkActionsWrapper.data['bulk-actions'].deactivateBulkActions()
      }
    }
    this.table.setAttribute('selected-rows', JSON.stringify(storedIds))
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-table').each((_, target) => {
      const $table = $(target)

      $table.tablesorter({
        cssDesc: 'c-table_cell--sorted-desc'
      })

      if (!$table.data('initialized')) {
        $table.data('instance', new DesignSystemTable($table))
        $table.data('initialized', true)
      }
  })
})
