/* global $ */

class DesignSystemAlert {
  constructor ($alert) {
    this.$alert = $alert
  }

  events () {
    this.$alert.on('click', () => {
      this.handleClick()
    })
  }

  handleClick () {
    const $btn = $(this.$alert).find('.js-dismiss')
    $btn.on('click', (e) => {
      this.dismissHandler(e, $btn)
    })
  }

  // Register click handler to dismiss the alert before the link is
  // followed if it's an XHR request.
  dismissHandler (e, $btn) {
    const isRemote = $btn.data('remote')

    if (isRemote) {
      e.preventDefault()
      $.when(this.dismiss($btn)).done(() => {
        $btn.trigger('click', this.dismissHandler)
      })
    } else {
      this.dismiss()
    }
  }

  // Removal behaviour
  dismiss ($alert) {
    $alert.closest('.c-alert').remove()
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-alert').each((index, target) => {
    const $alert = $(target)
    if (!$alert.data('initialized')) {
      new DesignSystemAlert($alert).events()
      $alert.data('initialized', true)
    }
  })
})
