/* global $ */

class DesignSystemButton {
  constructor ($button) {
    this.$button = $button
    this.classes = ''
    this.loader = this.$button.hasClass('js-button--loader')
    this.loading = false
    this.disabled = this.$button.hasClass('c-button--disabled')
  }

  init () {
    if (!this.$button.data('initialized')) {
      const classes = this.$button.attr('class') || ''
      this.classes = classes.replace(/(^|\s)js-\S+/g, '')

      if (this.$button.hasClass('js-remove-component')) {
        this.$button.on('click', (e) => {
          e.preventDefault()
          this.$button.remove()
        })
      }

      if (this.loader) {
        this.$button.on('click', () => { this.toggleLoader() })
        $('body').on('modal:open modal:close', () => { this.removeLoader() })
      }

      if (this.$button.hasClass('js-toggle-collapse')) {
        this.$button.on('click', (e) => {
          e.preventDefault()
          this.toggleRelatedContainerState()
        })
      }

      this.$button.data('initialized', true)
      this.$button.data('button', this)
    }
  }

  addLoader () {
    if (this.loading) return

    const width = this.$button.css('width')
    this.$button.before(this.loaderHTML(this.classes, width))
    this.$button.addClass('is-hidden')
    this.loading = true
  }

  removeLoader () {
    if (!this.loading) return

    this.$button.prev('.js-button-loader-dummy').remove()
    this.$button.removeClass('is-hidden')
    this.loading = false
  }

  toggleLoader () {
    if (!this.loading) {
      this.addLoader()
    } else {
      this.removeLoader()
    }
  }

  disable () {
    if (this.disabled) return

    this.$button.addClass('c-button--disabled')
    this.$button.attr('disabled', true)
    this.disabled = true
  }

  enable () {
    if (!this.disabled) return

    this.$button.removeClass('c-button--disabled')
    this.$button.attr('disabled', false)
    this.disabled = false
  }

  toggleDisabled () {
    if (this.disabled) {
      this.enable()
    } else {
      this.disable()
    }
  }

  toggleRelatedContainerState () {
    if (this.$button.hasClass('js-toggle-collapse')) {
      // eslint-disable-next-line vars-on-top
      const $target = $(this.$button.attr('href'))
      // eslint-disable-next-line vars-on-top
      const $buttonState = (this.$button.attr('aria-expanded') === 'true')

      // Set the new state for button and container
      if ($buttonState) {
        $target.addClass('is-collapsed')
        this.$button.addClass('is-collapsing')
      } else {
        $target.removeClass('is-collapsed')
        this.$button.removeClass('is-collapsing')
      }

      // Update button ARIA state
      this.$button.attr('aria-expanded', !$buttonState)
      $(document).trigger('charlieLayoutUpdated')
    }
  }

  loaderHTML (classes, width) {
    return `<div class="${classes} c-button--disabled js-button-loader-dummy" style="width:${width}">
              <svg class="c-button__loader" viewBox="0 0 50 50">
                <title>Loading</title>
                <path d="M12.2 25c0-7.1 5.7-12.8 12.8-12.8 5.3 0 9.8 3.2 11.8 7.8M37.8 25c0 7.1-5.7 12.8-12.8 12.8-5.4 0-10-3.3-11.9-8M34.4 27.8l3.4-2.8 2.8 3.4"></path>
                <path d="M15.6 22.2L12.2 25l-2.8-3.4"></path>
              </svg>
            </div>`
  }
}

$(document).on('ready ajaxComplete', function () {
  $('.js-button--loader, .js-toggle-collapse, .js-button').each(function () {
    if ($(this).hasClass('e-button')) { return }

    if (!$(this).data('initialized')) {
      new DesignSystemButton($(this)).init()
    }
  })
})
