// Import this file into your work instead of `@appsignal/javascript` to ensure proper
// setup of the exception catcher

import Appsignal from '@appsignal/javascript';

import { plugin as pluginBreadcrumbsNetwork } from '@appsignal/plugin-breadcrumbs-network';
import { plugin as pluginPathDecorator } from '@appsignal/plugin-path-decorator';
import { plugin as pluginWindowEvents } from '@appsignal/plugin-window-events';

const appsignal = window.env ? new Appsignal({
  key: window.env.APPSIGNAL_FRONTEND_API_KEY,
  namespace: 'frontend',
  ignoreErrors: [
    /Forbidden/i,
    /Not Found/i,
    /Unauthorized/i,
    /ResizeObserver loop limit exceeded/i,
  ],
}) : undefined;

if (appsignal) {
  appsignal.use(pluginBreadcrumbsNetwork());
  appsignal.use(pluginPathDecorator());

  // Disable pluginWindowEvents if it get's too noisy. It captures random errors from the console.
  // This is potentially useful until we have a better error catching strategy implemented
  // in the front-end
  appsignal.use(pluginWindowEvents());
}

export default appsignal;
