/** CORE - start - load before ../core/index.js **/
// Import polyfills for modern JavaScript features and make them globally available.
import 'core-js';

// Ensure this pack generates a css file even if empty.
import '../core/base.scss';

// Load and initialize the Hotwire stack
import '../core/hotwire.js'

import '../core/startup.js';
import '../core';
/** CORE - end **/

import "jquery-ui/ui/widgets/sortable";

import "../../../../vendor/assets/javascripts/typed";
import "../../../../vendor/assets/javascripts/js.cookie";

import "../functions/alerts.js";
import "../functions/sidebar.js";
import "../functions/helpers.js";

import "../modules/charlie.annotations.js";
import "../metrics/utm_cookies";

import "../components/";
import "../../../components"

$(document).on('ready', function() {
  $('html').addClass('page-ready');

  // Animate and hide alerts
  setTimeout(function() {
    $('.alert-info, .alert-success').slideUp(300);
  }, 2500);

  // Simple function to set and reset styling on a group of obejcts
  function setObjectStyling(setter, objects, styling, resetOtherObjects) {
    $(setter).on('click', function(e) {
      var forObject = $(this).data('for');
      var $object = $(objects + '[data-for="' + forObject + '"]');

      e.preventDefault();

      if (resetOtherObjects) {
        $(objects).removeClass(styling);
      }

      $object.addClass(styling);
    });
  }
});

$(document).on('ready', function() {
  var initCarousel = function(scope) {

    // Timeouts & Timers
    var TRANSITION_TIMER;
    var DELAY = 7500;
    var assignOptionToParent = !!scope.data('assign-option');

    // Objects
    var $selectors = scope.find('.js-carousel-selector');
    var $options   = scope.find('.js-carousel-option');

    // Functions
    var startTimer = function() {
      TRANSITION_TIMER = setTimeout(selectNextOption, DELAY);
    };

    var resetTimer = function() {
      clearTimeout(TRANSITION_TIMER);
      startTimer();
    };

    var selectNextOption = function() {
      var $nextSelector;

      $selectors.each(function(i) {
        if ($(this).hasClass('is-selected')) {
          if (i === ($selectors.length - 1)) {
            return $nextSelector = $selectors.first();
          } else {
            return $nextSelector = $(this).next('.js-carousel-selector');
          }
        }
      });
      selectOption($nextSelector);
      resetTimer();
    };

    var selectOption = function($selector) {
      var option = $selector.data('option');
      var $option = scope.find('.js-carousel-option[data-option="' + option + '"]');

      if (assignOptionToParent) {
        scope.addClass(option);
        scope.removeClass(scope.data('option'));
        scope.data('option', option);
      }

      $selectors.removeClass('is-selected');
      $selector.addClass('is-selected');
      $options.removeClass('show');
      $option.addClass('show');
    };

    var select = function($selector) {
      resetTimer();

      if (!$selector.hasClass('is-selected')) {
        selectOption($selector);
      }
    };

    $selectors.on('click', function() {
      select($(this));
    });

    scope.find('.js-carousel-option-alias').on('click', function() {
      var option = $(this).data('option');
      var $selector = scope.find('.js-carousel-selector[data-option="' + option + '"]');

      select($selector);
    });

    startTimer();
  };

  if ($('.js-carousel').length) {
    $('.js-carousel').each(function() {
      initCarousel($(this));
    });
  }
});
