// /* global $ */

class DesignSystemProgressNavbar {
  constructor ($progressNavbar) {
    this.progressNavbar = $progressNavbar[0]
    this.steps = this.progressNavbar.querySelectorAll('.js-progress-navbar__step')
    this.completedUpToStep = this.progressNavbar.getAttribute('completed-up-to')
    this.stateNames = [
      'is-current',
      'is-progressing-to',
      'is-completing',
      'is-incomplete',
      'is-in-progress',
      'is-complete',
      'is-complete-current',
      'is-complete-future',
    ]
  }

  init () {
    this.loadCurrentStep()
  }

  currentStep () {
    return this.progressNavbar.querySelector('.js-progress-navbar__step.is-current')
  }

  loadCurrentStep () {
    this.currentStep().classList.add('is-progressing-to')
  }

  enableLink (step) {
    const href = step.getAttribute('href')
    const dataHref = step.getAttribute('data-href')
    if (href) {
      step.classList.add('is-link')
      step.setAttribute('href', href)
      step.setAttribute('data-href', '')
    }

    if(dataHref) {
      step.classList.add('is-link')
      step.setAttribute('href', dataHref)
      step.setAttribute('data-href', dataHref)
    }

    if (step.getAttribute('step-index') > this.completedUpToStep) {
      this.completedUpToStep = step.getAttribute('step-index')
    }
  }

  changeStepState (step, newState) {
    const counter = step.querySelector('.js-progress-navbar__step-counter')
    const counterInstance = $(counter).data('instance')

    step.classList.remove(...this.stateNames)
    step.classList.add(...newState.split(' '))

    if (typeof counterInstance !== 'undefined') {
      if (step.classList.contains('is-complete') ||
        step.classList.contains('is-complete-current') ||
        step.classList.contains('is-complete-future')
      ) {
        step.getAttribute('complete', true)
        counterInstance.changeState('complete')
        this.enableLink(step)
      } else if (step.classList.contains('is-current')) {
        counterInstance.changeState('in-progress')
        this.enableLink(step)
      } else {
        counterInstance.changeState('incomplete')
      }
    }
  }

  finishCurrentStep () {
    this.changeStepState(this.currentStep(), 'is-complete is-completing')
  }

  changeCurrentStep (newStep) {
    this.steps.forEach((step, index ) => {
      const thisStep = step
      if (index === newStep) {
        if (thisStep.getAttribute('complete')) {
          this.changeStepState(thisStep, 'is-complete-current is-current');
        } else {
          this.changeStepState(thisStep, 'is-in-progress is-current');
        }
      } else if (index > newStep) {
        if (thisStep.getAttribute('complete')) {
          this.changeStepState(thisStep, 'is-complete-future');
        } else {
          this.changeStepState(thisStep, 'is-incomplete');
        }
      } else {
        this.changeStepState(thisStep, 'is-complete');
      }
    });

    this.loadCurrentStep();
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-progress-navbar').each((_, target) => {
    const $progressNavbar = $(target)
    if (!$progressNavbar.data('initialized')) {
      $progressNavbar.data('instance', new DesignSystemProgressNavbar($progressNavbar))
      $progressNavbar.data('instance').init()
      $progressNavbar.data('initialized', true)
    }
  })
})
