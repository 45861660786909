export default class DesignSystemInputErrors {
  constructor ($input) {
    this.$input = $input
    this.id = this.$input[0].getAttribute('id')
    this.$wrapper = this.$input[0].parentElement
  }

  removeError () {
    const errorElement = this.$wrapper.getElementsByClassName('js-input__error-message')[0]
    errorElement && errorElement.remove()

    this.$wrapper.getElementsByClassName('js-input')[0].classList.remove('has-error')
    this.$wrapper.getElementsByClassName('js-input')[0].removeAttribute('aria-required')
  }

  errorAsHTML (message) {
    const paragraph = document.createElement('p')
    paragraph.setAttribute('class', 'c-input__error-message js-input__error-message')
    paragraph.innerHTML = message
    return paragraph
  }

  setError (message) {
    this.removeError(this.$wrapper)

    this.$wrapper.getElementsByClassName('js-input')[0].classList.add('has-error')
    this.$wrapper.getElementsByClassName('js-input')[0].setAttribute('aria-required', 'true')
    this.$wrapper.getElementsByClassName('js-input__info')[0].append(this.errorAsHTML(message))
  }
}
