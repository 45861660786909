// Setup core charlie functions and variables.
require('./charlie');

// Shared JS code. Used on every request.
require('./shared');

// Import custom JavaScript.
require('../index');

import './animations';
import './chart';
import './checkbox';
import './cytoscape';
import './dropzone';
import './edit_company';
import './form_animation';
import './form_onboarding';
import './form';
import './image_previews';
import './main';
import './menus';
import './state_controller';
import './tab';
import './team_list';
import './time_off';
import './toggleOverlay';
