/* global charlie */

/**
 * Fired when the active step in a flow is changed. Examples of the flow are
 * Onboarding and creating a new Reviews v2 cycle.
 *
 * There is an older jQuery equivalent, `onboardingStepChange`, which this
 * supersedes. This event is still in use in JS built with Sprockets but should
 * be updated to this event when it's migrated.
 *
 * Usage:
 *
 * document.dispatchEvent(charlie.events.flowStepChange);
 * document.addEventListener('charlie:flow-step-change', () => { });
 */
charlie.events.flowStepChange = new Event('charlie:flow-step-change', {
  cancelable: true,
});
