/* global $ */

class DesignSystemAvatar {
  constructor ($avatar) {
    this.$avatar = $avatar
    this.$imageTarget = this.$avatar.find('.js-avatar-uploader-image')
  }

  init () {
    this.$avatar.dropzone({
      url: this.$avatar.data('url'),
      acceptedFiles: 'image/jpg, image/jpeg, image/png',
      maxFilesize: 20,
      thumbnailWidth: 360,
      thumbnailHeight: 360,
      previewTemplate: `<div style="display: none;">
                          <img data-dz-thumbnail />
                          <span data-dz-name></span>
                          <div data-dz-size></div>
                          <span style="display: none" data-dz-uploadprogress></span>
                          <span data-dz-errormessage></span>
                        </div>`,
      sending: (file, xhr) => {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        xhr.timeout = 25000
        xhr.ontimeout = () => {
          window.alert('Your request timed out. Try a smaller file or a faster internet connection.')
        }
        this.$avatar.addClass('is-loading')
      },
      processing: () => {
        this.$avatar.removeClass('has-error has-success')
        this.$avatar.addClass('is-loading')
      },
      dragleave: () => {
        this.$avatar.removeClass('is-dragging')
      },
      dragover: () => {
        this.$avatar.addClass('is-dragging')
      },
      drop: () => {
        this.$avatar.removeClass('is-dragging')
      },
      complete: () => {
        this.$avatar.removeClass('is-loading is-empty')
      },
      success: () => {
        this.$avatar.addClass('has-success')
      },
      error: () => {
        this.$avatar.addClass('has-error')
      },
      thumbnail: (file, dataUrl) => {
        if (file.accepted) {
          this.$avatar.find('[data-dz-thumbnail]').attr('alt', file.name)
          this.$avatar.find('[data-dz-thumbnail]').attr('src', dataUrl)
          this.$imageTarget.attr('style', `background-image: url(${dataUrl});`)
        }
      }
    })
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-avatar-uploader').each((index, target) => {
    const identifier = $(target).data('identifier')
    const $target = $(`.js-avatar-uploader[data-identifier=${identifier}]`)

    if ($target.length > 0) {
      new DesignSystemAvatar($target).init()
    }
  })
})
