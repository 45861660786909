/* global $, chart */

class DesignSystemDoughnutChart {

  constructor(doughnutChart) {
    this.doughnutChart = doughnutChart[0]

    if ( !this.doughnutChart.data || !this.doughnutChart.data['chart'] ) {
      const ctx     = this.doughnutChart.getContext("2d")
      const data    = this.doughnutChart.dataset.chartData ? JSON.parse(this.doughnutChart.dataset.chartData) : []
      const labels  = this.doughnutChart.dataset.chartLabels ? JSON.parse(this.doughnutChart.dataset.chartLabels) : []
      const colors  = this.doughnutChart.dataset.chartColors ? JSON.parse(this.doughnutChart.dataset.chartColors) : []
      const cutout  = this.doughnutChart.dataset.chartCutout

      const checklistGraph = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: labels,
          datasets: [{
            data: data,
            backgroundColor: colors,
            hoverBackgroundColor: colors
          }],
        },
        options: {
          animateRotate: data[0] == 100,
          cutout: cutout,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: labels.length > 2,
              titleFont: { weight: 'normal' }
            }
          }
        }
      })

      this.doughnutChart.data = { 'chart': checklistGraph }
    }
  }
}

$(document).on('ready ajaxComplete', () => {

  $('.js-doughnut_chart').each((_, target) => {
    const $doughnutChart = $(target)

    if (!$doughnutChart.data('doughnut-chart')) {
      $doughnutChart.data('doughnut-chart', new DesignSystemDoughnutChart($doughnutChart))
    }
  })
})
