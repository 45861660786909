/* global $ */

class DesignSystemCheckbox {
  constructor ($checkbox) {
    this.$checkbox = $checkbox
  }

  bindEvents () {
    this.$checkbox.on('click', () => {
      const $toggleControl = $(this.$checkbox.data('control'))
      const state = this.$checkbox.data('state')

      $toggleControl.toggleClass(state)
      $(document).trigger('charlieLayoutUpdated')
    })
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-checkbox-input').each((index, checkbox) => {
    const $checkbox = $(checkbox)
    const toggle = ($checkbox.data('control') && $checkbox.data('state'))
    if (typeof $checkbox.data('initialized') === 'undefined') {
      if (toggle) new DesignSystemCheckbox($checkbox).bindEvents()
      $checkbox.data('initialized', true)
    }
  })
})
