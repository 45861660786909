/* global $ */

class DesignSystemAvatarGroup {
  constructor ($wrapper) {
    this.wrapper = $wrapper
    this.limit = $wrapper.data('limit')
    this.overflowSingular = $wrapper.data('overflow-singular')
    this.overflowPlural = $wrapper.data('overflow-plural')
    this.overflowText = $wrapper.find('.js-avatar_group-text')
    this.count = $wrapper.data('count')
    this.overflowCount = 0
    this.visibleAvatarsCount = 0
    this.replaceVisibleAvatar = false

    const avatarGroup = {
      addAvatar: (id, url) => {
        this.addAvatar(id, url)
      },
      removeAvatar: (id) => {
        this.removeAvatar(id)
      },
      removeAllAvatars: () => {
        this.removeAllAvatars()
      },
      getCount: () => {
        this.getCount()
      }
    }

    this.wrapper[0].data = {'avatar-group': avatarGroup}
  }

  overflowCopy () {
    let word = this.overflowPlural

    if (this.count === 1 || this.overflowCount === 1) {
      word = this.overflowSingular
    }

    if (this.count <= this.limit) {
      return [
        this.count,
        word
      ].join(' ')
    } else {
      return [
        '+',
        this.overflowCount,
        'more',
        word
      ].join(' ')
    }
  }

  updateCounts ($wrapper) {
    this.count = $wrapper.find('.js-avatar').length
    this.visibleAvatarsCount = $wrapper.find('.js-avatar').not('.is-hidden').length
    this.replaceVisibleAvatar = (this.visibleAvatarsCount < this.limit)

    if (this.count <= this.limit) {
      this.overflowCount = 0
    } else {
      this.overflowCount = this.count - this.limit
    }
  }

  getCount () {
    return this.wrapper.find('.js-avatar').length
  }

  avatarAsHTML (id, url) {
    return `<div data-id="${id}" class="c-avatar c-avatar--squircle u-size--50 js-avatar is-hidden" style='background-image: ${url.toString()};'></div>`
  }

  addAvatar (id, url) {
    if (!this.wrapper.find(`.js-avatar[data-id="${id}"]`).length) {
      this.wrapper.find('.js-avatar_group__content').prepend(this.avatarAsHTML(id, url))

      this.updateCounts(this.wrapper)
      this.overflowText.removeClass('is-hidden').text(this.overflowCopy())

      if (this.count <= this.limit) {
        $(`.js-avatar[data-id="${id}"]`).removeClass('is-hidden')
      }
    }
  }

  removeAvatar (id) {
    this.wrapper.find(`.js-avatar[data-id="${id}"]`).remove()
    this.wrapper.find(`[data-member="${id}"]`).remove()

    this.updateCounts(this.wrapper)

    if (this.replaceVisibleAvatar) {
      this.wrapper.find('.js-avatar.is-hidden').first().removeClass('is-hidden')
    }

    this.overflowText.removeClass('is-hidden').text(this.overflowCopy())
  }

  removeAllAvatars () {
    this.wrapper.find('.js-avatar').remove()

    this.updateCounts(this.wrapper)
    this.overflowText.addClass('is-hidden')
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-avatar_group').each((_, target) => {
    const $avatarGroup = $(target)

    if (!$avatarGroup.data('initialized')) {
      $avatarGroup.data('instance', new DesignSystemAvatarGroup($avatarGroup))
      $avatarGroup.data('initialized', true)
    }
  })
})
