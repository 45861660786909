import { Controller } from "@hotwired/stimulus"
import moment from 'moment';


// Connects to data-controller="maternity-leave-calculator"
export default class extends Controller {
  #expectedDOB;
  #statMaternityPayQualificationDate;
  #deadlineToNotifyEmployer;
  #matPayCalculationStartDate;
  #matPayCalculationEndDate;
  #matLeaveEarliestStartDate;
  #matLeaveTriggeredByIllnessDate;
  #shadowRoot = document.querySelector('#ento-part-maternity-leave-calculator').shadowRoot;

  calculate() {
    this.#expectedDOB = this.#shadowRoot.getElementById('dueDate').value;

    this.setDateValues();
    this.updateFormValues();
    this.updateStyles();
  }

  setDateValues() {
    this.#statMaternityPayQualificationDate =  moment(this.#expectedDOB).subtract(41, 'weeks').format('YYYY-MM-DD')
    this.#deadlineToNotifyEmployer =  moment(this.#expectedDOB).subtract(15, 'weeks').format('YYYY-MM-DD')
    this.#matPayCalculationStartDate =  moment(this.#expectedDOB).subtract(23, 'weeks').format('YYYY-MM-DD')
    this.#matPayCalculationEndDate =  moment(this.#expectedDOB).subtract(15, 'weeks').format('YYYY-MM-DD')
    this.#matLeaveEarliestStartDate =  moment(this.#expectedDOB).subtract(11, 'weeks').format('YYYY-MM-DD')
    this.#matLeaveTriggeredByIllnessDate =  moment(this.#expectedDOB).subtract(4, 'weeks').format('YYYY-MM-DD')
  }

  updateFormValues() {
    this.#shadowRoot.querySelector('#js-result--qualification-date').value = this.#statMaternityPayQualificationDate
    this.#shadowRoot.querySelector('#js-result--calculation-start-date').value = this.#matPayCalculationStartDate
    this.#shadowRoot.querySelector('#js-result--calculation-end-date').value = this.#matPayCalculationEndDate
    this.#shadowRoot.querySelector('#js-result--inform-employer').value = this.#deadlineToNotifyEmployer
    this.#shadowRoot.querySelector('#js-result--earliest-start-date').value = this.#matLeaveEarliestStartDate
    this.#shadowRoot.querySelector('#js-result--illness-trigger-date').value = this.#matLeaveTriggeredByIllnessDate
  }

  updateStyles() {
    const informationalDatepickerDivs = this.#shadowRoot.querySelectorAll('.js-informational-datepicker');

    informationalDatepickerDivs.forEach(datepickerDiv => {
      const inputElement = datepickerDiv.querySelector('input');
      inputElement.style.backgroundColor = 'white';
    });
  }
}
