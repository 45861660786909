/* global $ */
class DesignSystemTabbedContent {
  constructor ($tabbedContent) {
    this.$tabbedContent = $tabbedContent
  }

  events () {
    this.$tabbedContent.find('.c-tabbed_content__tab a').each((index, tab) => {
      tab.addEventListener('click', (e) => {
        this.handleClick(e, tab)
      })
    })
  }

  handleClick (e, $tab) {
    e.preventDefault()

    // Get elements we'll be manipulating. Remember to scope to the closest
    // .js-tab-container as there could be multiple Tabbed Content components
    // on a single page.
    const target = $tab.getAttribute('data-target')
    const targetedTab = $tab.closest('.c-tabbed_content__tab')
    const tabbedContent = $tab.closest('.js-tabbed-content')
    const targetedContent = tabbedContent.querySelector(`[id="${target}"]`)
    const currentTab = tabbedContent.querySelector('.c-tabbed_content__tab--active')
    const currentContent = tabbedContent.querySelector('.c-tabbed_content__content--active')

    // Make the current active elements inactive.
    currentTab.classList.remove('c-tabbed_content__tab--active')
    currentTab.removeAttribute('aria-selected')
    currentTab.querySelector('[tabindex="-1"]')

    currentContent.classList.remove('c-tabbed_content__content--active')
    currentContent.setAttribute('hidden', true)

    // Make the selected elements active.
    targetedTab.classList.add('c-tabbed_content__tab--active')
    currentTab.setAttribute('aria-selected', true)
    currentTab.querySelector('a').setAttribute('tabindex', '0')

    targetedContent.classList.add('c-tabbed_content__content--active')
    targetedContent.removeAttribute('hidden')
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-tabbed-content').each((index, target) => {
    const $tabbedContent = $(target)
    if (!$tabbedContent.data('initialized')) {
      new DesignSystemTabbedContent($tabbedContent).events()
      $tabbedContent.data('initialized', true)
    }
  })
})
