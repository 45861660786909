// ------------------------------------------------\\
// Options used by ChartJS
// ------------------------------------------------\\

const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

Chart.defaults.animation.duration = prefersReducedMotion ? 0 : 750;
Chart.defaults.animation.easing = 'easeInOutCubic';
Chart.defaults.elements.arc.borderWidth = 0;
Chart.defaults.elements.line.fill = false;
Chart.defaults.responsive = true;

// Set default style of tooltips
Chart.defaults.hover.animationDuration = prefersReducedMotion ? 0 : 750;
Chart.defaults.font.family = "'futura-pt', sans-serif";
Chart.defaults.font.size = 14;
Chart.defaults.plugins.tooltip.enabled = false;
Chart.defaults.plugins.tooltip.backgroundColor = 'rgba(42, 68, 80, 0.85)';
Chart.defaults.plugins.tooltip.bodyFontFamily = "'futura-pt', sans-serif";
Chart.defaults.plugins.tooltip.bodyFontSize = 14;
Chart.defaults.plugins.tooltip.xPadding = 8;
Chart.defaults.plugins.tooltip.yPadding = 8;
Chart.defaults.plugins.tooltip.cornerRadius = 8;
