import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.loader = this.element.classList.contains('js-button--loader')
    this.loading = false
    this.disabled = this.element.classList.contains('c-button--disabled')
  }

  load(event) {
    if (!this.loading) {
      const classes = this.element.getAttribute('class').split(' ').filter(identifier => !identifier.match(/(^|\s)js-\S+/));
      const width = getComputedStyle(this.element)['width'];
      const dummyLoader = document.createElement('div');
      dummyLoader.innerHTML = this.loaderHTML(classes.join(' '), width).trim();
      this.element.before(dummyLoader);
      this.element.classList.add('is-hidden');
      this.loading = true;
    } else {
      const prev = this.element.previousElementSibling;
      if (prev && prev.matches('.js-button-loader-dummy')) {
        prev.remove();
      }
      this.element.removeClass('is-hidden')
      this.loading = false
    }
  }

  loaderHTML(classes, width) {
    return `<div class="${classes} c-button--disabled js-button-loader-dummy" style="width:${width}">
              <svg class="c-button__loader" viewBox="0 0 50 50">
                <title>Loading</title>
                <path d="M12.2 25c0-7.1 5.7-12.8 12.8-12.8 5.3 0 9.8 3.2 11.8 7.8M37.8 25c0 7.1-5.7 12.8-12.8 12.8-5.4 0-10-3.3-11.9-8M34.4 27.8l3.4-2.8 2.8 3.4"></path>
                <path d="M15.6 22.2L12.2 25l-2.8-3.4"></path>
              </svg>
            </div>`
  }
}
