 $(document).on('ready ajaxComplete', function() {

  //last step of onboarding
  function previewImage(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function(e) {
        $('#profile-picture-preview').attr('style', 'background-image: url("' + e.target.result + '");');
      };

      reader.readAsDataURL(input.files[0]);
    }
  }

  $("#profile_picture_uploader").change(function() {
    if (this.files[0].size < 2000000) {
      previewImage(this);
    }
  });

});
