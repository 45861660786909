$(document).on('ready ajaxComplete', function() {

  // Select time off calender integration link
  $('.js-input-select').on('click', function() {
    $(this).select();
  });

  // REVIEW: find a relevant spot for this
  $('.js-hint-content').off().on('mouseenter click', function() {
    var $next = $(this).next();

    if ($next.hasClass('is-active')) {
      $next.removeClass('is-active');
    } else {
      $next.addClass('is-active');
    }
  });

  $('.js-hint-content').on('mouseleave', function() {
    $(this).next().removeClass('is-active');
  });

  $('.js-reset-state-on-action').off().on('click', function() {
    var action = $(this).data('action');
    var selector_target = $(this).data('target');
    var target = $('.' + selector_target);

    target.removeClass(action);
  });

  $('.js-state-on-action').off().on('click', function() {
    var action = $(this).data('action');
    var selector_target = $(this).data('target');
    var target = $(this).closest('.' + selector_target);

    target.addClass(action);
  });
}); // ready

//--------------------------------------------------
//  time off functions
//--------------------------------------------------

function initTimeOffForm() {

  $("#new_leave_request").on("submit", function(){
    if (leaveTypeIsDeductible($(this)))  {
      setDurationInHoursValues($(this));
      setHourlyReviewformAction($(this));
    } else {
      clearDurationInHoursValues($(this));
    }

    return true;
  })

  $(".edit_leave_request[id*='edit_leave_request']").on("submit", function(){
    if (leaveTypeIsDeductible($(this))) {
      setDurationInHoursValues($(this));
      setHourlyEditformAction($(this));
    } else  {
      clearDurationInHoursValues($(this));
    }

    return true;
  })

  //// When you select a different time off type
  $('.js-charlie-select').on('change', function() {
    var $this = $(this),
        deductible_leave_types  = $this.data('deductible-leave-types');

    /// If there are no deductible leave types we shouldn't toggle anything
    if (deductible_leave_types.length) {
      toggleDurationFields($this.closest('form'))
    }

  });

  function toggleDurationFields(form) {
    if (leaveTypeIsDeductible(form)) {
      showDurationInHours(form)
    } else {
      showDurationInDays(form)
    }
  }

  function showDurationInHours(form) {
    form.find('.hours_input').removeClass('is-hidden')
    form.find('#hourly_multi_day_switch').removeClass('is-hidden')
    form.find('.js-end-date').addClass('is-hidden')
  }

  function showDurationInDays(form) {
    form.find('.hours_input').addClass('is-hidden')
    form.find('#hourly_multi_day_switch').addClass('is-hidden')
    form.find('.js-end-date').removeClass('is-hidden')
  }

  function setDurationInHoursValues(form) {
    isMultiDay = form.find('#js-leave-request-multiple-days-switch').prop('checked')
    form.find('input[name="leave_request[one_day]"]').val(!isMultiDay)
    form.find('input[name="leave_request[morning]"]').val(0)
    form.find('input[name="leave_request[afternoon]"]').val(0)
    hourly_end_date = form.find('input[name="hourly_leave_request[end_date]"]').val()
    form.find('input[name="leave_request[end_date]"]').val(hourly_end_date)
  }

  function clearDurationInHoursValues(form) {
    form.find('input[name="leave_request[hours]"').val('')
  }

  function setHourlyEditformAction(form) {
    action  = form.find('input[name="leave_request[hours]').data('hourly-edit-action');
    form.attr('action', action)
  }

  function setHourlyReviewformAction(form) {
    action  = form.find('input[name="leave_request[hours]').data('hourly-review-action');
    form.attr('action', action)
  }

  function leaveTypeIsDeductible(form) {
    deductible_leave_types  = form.find('.js-charlie-select').data('deductible-leave-types');
    selected_leave_type = form.find('.js-charlie-select').find(':selected').text();

    return $.inArray(selected_leave_type, deductible_leave_types) != -1
  }
} // END initTimeOffForm

// Global JS Function
// We're using this function in our HTML files
window.initTimeOffForm = initTimeOffForm;
