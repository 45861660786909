$(document).on('ready ajaxComplete teamsLoaded', function() {

  //// Change section with mobile select field
  $('.js-charlie-filter-select').off().on('change', function() {
    var $this = $(this),
        value = $this.find(":selected").attr('value');

    // console.log(value);

    $('.js-charlie-filter-btn[data-filter-type="' + value + '"]')[0].click();
  });


});
