/* global $ */

class DesignSystemNavbar {
  constructor (navbar) {
    this.navbar = navbar[0]
    this.dropdownTriggers = this.navbar.querySelectorAll('.js-navbar__dropdown-trigger')
    this.dropdowns = this.navbar.querySelectorAll('.js-navbar__dropdown')
  }

  init () {
    this.initDropdownTrigger()
  }

  hideNavbarDropdowns () {
    this.dropdown.forEach((dropdown) => {
      dropdown.classList.remove('is-open')
    })
  }

  openNavbarDropdown (dropdown) {
    dropdown.classList.add('is-open')
  }

  initDropdownTrigger () {
    this.dropdownTriggers.forEach((trigger) => {
      trigger.addEventListener('mousedown', () => {
        const dropdownParent = trigger.parentElement
        if ( trigger.classList.contains('is-open') ) {
          this.hideNavbarDropdown();
        } else {
          this.hideNavbarDropdown();
          this.openNavbarDropdown(dropdownParent);
        }
      })

      trigger.addEventListener('mouseenter focusin', () => {
        const dropdownParent = trigger.parentElement
        this.hideNavbarDropdown()
        this.openNavbarDropdown(dropdownParent)
      })

      trigger.addEventListener('mouseleave focusout', (e) => {
        const dropdownParent = trigger.parentElement
        if(e.target !== dropdownParent && e.target.parentElement === dropdownParent){
          this.hideNavbarDropdown()
        }
      })
    })

    this.dropdowns.forEach((dropdown) => {
      dropdown.addEventListener('mouseleave', () => {
        this.hideNavbarDropdown()
      })
    })
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-navbar').each((_, target) => {
    const $navbar = $(target)

    if (!$navbar.data('initialized')) {
      $navbar.data('instance', new DesignSystemNavbar($navbar))
      $navbar.data('instance', new DesignSystemNavbar($navbar).init())
      $navbar.data('initialized', true)
    }
  })
});
