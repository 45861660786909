

//--------------------------------------------------
// Set module states
//--------------------------------------------------

function changeModuleState(state, module, activator, stayOnState) {
  // module == parent elemnent containing all states
  // state  == the new state of the module
  // // toggle == should state be removed if you click again?
  activator = (typeof activator !== 'undefined') ? activator : false;
  stayOnState = stayOnState || false;

  var CurrentState;

  // Default list of states
  // -------------------------------
  var all_states = "is-showing is-editing is-editing-child is-locked is-loading is-previewing has-history has-upcoming has-info has-error has-success";
  // -------------------------------
  // when-showing hides when another state is active
  // when-editing shows when is-editing is active
  // when-locked shows when is-locked is active
  // -------------------------------
  // States set by Charlie not user:
  // -------------------------------
  // when-loading shows when is-loading is active
  // has-error

  //// Check if state is already showing
  //// Reset states and toggle the clicked state
  if ( stayOnState === false ) {
    CurrentState = module.hasClass(state);
  } else {
    CurrentState = false;
  }

  module.removeClass(all_states);

  if ( !CurrentState ) {
    module.addClass(state);
  }

  if ( activator !== false ) {
    if ( ( activator.hasClass('is-active') || activator.hasClass('const-active') ) && module.hasClass('js-trigger-save-on-edit') && module.find('form').length ) {
      module.find('form').find('.js-submit-btn').trigger('click');
    }
    if ( !activator.hasClass('is-active') && module.hasClass('js-load-on-edit') ) {
      $.ajax({
        type: "GET",
        url: module.data('path'),
        dataType: 'script',
      });
    }
  }

  module.find('.is-active').removeClass('is-active');

  if ( activator !== false ) {
    if ( !CurrentState ) {
      activator.addClass('is-active');
    }
  }

  // console.log('triggered module state change');
  // $(document).trigger('moduleStatesChanged');
  $(document).trigger('charlieLayoutUpdated');
}

// Global JS Function
// We're using this function in our HTML files
window.changeModuleState = changeModuleState;
window.addModuleState = addModuleState;

function addModuleState(state, module) {
  module.addClass(state);
}

function toggleMobileAccordion(module) {
  module.toggleClass('is-open--mobile');
}

function resetModuleState(module) {
  module = module || 'Nuke from orbit';

  var all_states = "is-showing is-editing is-editing-child is-locked is-loading is-previewing has-history has-info has-error has-success";

  if ( module == 'Nuke from orbit') {
    $(all_states).removeClass(all_states);
    $(all_states).find('.js-change-state, .js-show-historic').removeClass('is-active');
  } else {
    $(module).removeClass(all_states);
    module.find('.js-change-state, .js-show-historic').removeClass('is-active');
  }
}


$(document).on('ready ajaxComplete', function() {


  $('.js-set-loading').on('click', function() {
    var $this = $(this),
        state = $this.data('state-type'),
       module = $this.closest('.js-states-container');

    changeModuleState(state, module, $this);
  });

  //// Default state change activator
  // On click get the state you want
  // and find the closest parent module
  $('.js-change-state').off().on('click', function() {
    var $this = $(this),
        state = $this.data('state-type'),
       module = $this.closest('.js-states-container'),
       stayOnState = $this.data('stay-on-state');

    changeModuleState(state, module, $this, stayOnState);
  });

  $('.js-add-state').on('click', function() {
    var $this = $(this),
        state = $this.data('state-type'),
       module = $this.closest('.js-states-container');

    module.addClass(state);
  });

  $('.js-reset-state').off().on('click', function() {
    var module = $(this).closest('.js-states-container');
    resetModuleState(module);
    module.find('.js-editing-target').html('');
  });


  $('.js-remove-module').off().on('click', function() {
    var module = $(this).closest('.js-states-container');
    module.remove();
  });


  //// Toggle mobile accordion
  $('.js-mobile-toggle').off().on('click', function() {
    var module = $(this).closest('.js-states-container');
    toggleMobileAccordion(module);
  });

  $('.js-table-mobile-toggle').off().on('click', function() {
    var module = $(this).closest('.js-states-container');
    toggleMobileAccordion(module);
  });


  // Charlie dashboard hud toggle
  $('.js-change-hud-state').off().on('click', function() {
    var $this = $(this),
        state = $this.data('state-type'),
       module = $this.closest('.js-states-container');

    changeModuleState(state, module, $this);
  });




  //// Load in more content on click
  $('.js-load-team-content').off().on('click', function() {
    var $this = $(this),
       module = $this.closest('.js-states-container'),
       member = module.data('team-member-id'),
         type = $this.data('type');

    changeModuleState('is-loading', module, false);

    $.ajax({
      type: "GET",
      url: "/team/inspect_team_member/" + member + "/" + type,
      dataType: 'script',
    });
  });



  //// Change card to history state
  $('.js-show-historic').off().on('click', function() {
    var $this = $(this),
         name = $this.data('name'),
         type = $this.data('type'),
       member = $this.data('member'),
       module = $this.closest('.js-states-container'),
      content = module.find('.js-history');

    if ( module.hasClass('has-history') ) {
      module.removeClass('is-active');
      $this.addClass('go-back');

      setTimeout(function() {
        $this.removeClass('go-back');
      }, 700);

      resetModuleState(module);
      content.html('');

    } else {
      $.ajax({
        type: "GET",
        url: "/people/" + member + "/history/" + type,
        dataType: 'script',
        beforeSend: function(){
          changeModuleState('is-loading', module, $this);
          $this.toggleClass('is-active');
        },
        complete: function(){
          changeModuleState('has-history', module, $this);
        },
        success: function() {
        },
        error: function() {
          content.html("<span class='text--danger text--center'>Charlie could not load the " + name + " history, reload the page and try again. If the problem persists let us know!</span>");
        }
      });
    }
  });

  $(document).trigger('stateControllerReady');
}); // END document ready
