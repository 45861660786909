/* global $ */

class DesignSystemMultilevelSidebar {
  constructor (multilevelSidebar) {
    this.multilevelSidebar = multilevelSidebar[0]
  }

  init () {
    this.addEventsListeners()
  }

  addEventsListeners () {
    this.multilevelSidebar.addEventListener('click', (e) => {
      this.handleMenu()
    })

    this.multilevelSidebar.addEventListener('keypress', (e) => {
      this.handleMenu()
    })
  }

  handleMenu () {
    const checkbox = this.multilevelSidebar.parentElement.querySelector('.c-checkbox__input')
    const list = this.multilevelSidebar.parentElement.querySelector('.c-multilevel_sidebar__list')
    const sidebarItem = this.multilevelSidebar.parentElement

    if(this.multilevelSidebar.classList.contains('c-multilevel_sidebar__expander-expanded')) {
      this.multilevelSidebar.classList.remove('c-multilevel_sidebar__expander-expanded')
      sidebarItem.classList.remove('c-multilevel_sidebar__item-expanded')
      checkbox.removeAttribute('checked')
      list.style.display = "none"
    } else {
      this.multilevelSidebar.classList.add('c-multilevel_sidebar__expander-expanded')
      sidebarItem.classList.add('c-multilevel_sidebar__item-expanded')
      checkbox.setAttribute('checked', 'checked')
      list.style.display = "block"
    }
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.c-multilevel_sidebar__expander').each((index, target) => {
    const $multilevelSidebar = $(target)
    if (!$multilevelSidebar.data('initialized')) {
      new DesignSystemMultilevelSidebar($multilevelSidebar)
      new DesignSystemMultilevelSidebar($multilevelSidebar).init()
      $multilevelSidebar.data('initialized', true)
    }
  })
})
