export default class DesignSystemInputState {
  constructor ($input) {
    this.$input = $input
    this.disabled = $input.hasClass('c-input--disabled')
  }

  disable () {
    if (this.disabled) return

    this.$input.addClass('c-input--disabled')
    this.$input.attr('disabled', true)
    this.disabled = true
  }

  enable () {
    if (!this.disabled) return

    this.$input.removeClass('c-input--disabled')
    this.$input.attr('disabled', false)
    this.disabled = false
  }

  toggleDisabled () {
    if (this.disabled) {
      this.enable()
    } else {
      this.disable()
    }
  }
}
