import { createPopper } from '@popperjs/core';

export default class Popper {
  constructor(popper, visibleOnLoad, control) {
    this.popperContainer = popper;
    this.control = control || document.querySelector(this.popperContainer.dataset.control);
    this.visibleOnLoad = visibleOnLoad;
  }

  init(events = ['mouseenter', 'focus', 'mouseleave', 'blur']) {
    this.initPopper();
    this.initEvents(events);
    if(this.visibleOnLoad) {
      this.showPopper();
    } else {
      this.hidePopper();
    }
  }

  initPopper() {
    this.popper = createPopper(this.control, this.popperContainer, {
      placement: this.popperContainer.dataset.placement,
      modifiers: [
        { name: 'offset', options: { offset: [0, 5] } },
      ],
    });
  }

  initEvents(events) {
    events.forEach((event) => {
      this.control.addEventListener(event, () => { this.togglePopper(); });
    });
  }

  togglePopper() {
    if (this.popperContainer.hasAttribute('data-show')) {
      this.hidePopper();
    } else {
      this.showPopper();
    }
  }

  showPopper() {
    this.popperContainer.setAttribute('data-show', '');
    this.enablePopperEventListeners();

    // Tell Popper to update it's positioning.
    this.popper.update();
  }

  hidePopper() {
    this.popperContainer.removeAttribute('data-show');

    // Even when the popper is hidden the events that re-position it on scroll
    // are still being fired. We can disable these when hiding the popper and
    // re-enable them when it's show.
    this.disablePopperEventListeners();
  }

  enablePopperEventListeners() {
    this.popper.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: 'eventListeners', enabled: true },
      ],
    }));
  }

  disablePopperEventListeners() {
    this.popper.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: 'eventListeners', enabled: false },
      ],
    }));
  }
}
