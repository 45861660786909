/* global $ */

class DesignSystemCheckboxGroup {
  constructor ($checkboxGroup) {
    this.$checkboxGroup = $checkboxGroup[0]
    this.$selectAll = this.$checkboxGroup.querySelectorAll('.js-checkbox-group__select-all')[0]
    this.$clearAll = this.$checkboxGroup.querySelector('.js-checkbox-group__clear-all')
  }

  events () {
    const inputWrappers = this.$checkboxGroup.querySelectorAll('.js-checkbox-group__input-wrapper')
    inputWrappers.forEach(inputWrapper => {
      inputWrapper.addEventListener('click', (event) => {
        event.preventDefault()
        const $input = inputWrapper.querySelector('.c-checkbox__input')
        this.toggleInputValue($input)
      })
    })

    this.$selectAll.addEventListener('click', (event) => {
      event.preventDefault()

      this.handleSelectAll()
    })

    this.$clearAll.addEventListener('click', (event) => {
      this.handleClearAll()
    })
  }

  toggleInputValue ($input) {
    if(!$input.getAttribute('disabled')){
      if ($input.getAttribute('checked') != null) {
        $input.removeAttribute('checked')
      } else {
        $input.setAttribute('checked', true)
      }
      this.checkFormInputs()
    }
  }

  checkFormInputs () {
    const isChecked = this.$checkboxGroup.querySelectorAll('input:checked').length > 0
    if (isChecked) {
      this.enableClearAllButton()
    } else {
      this.disableClearAllButton()
    }

    const countChecked = this.$checkboxGroup.querySelectorAll('input:checked').length
    const countInputs = this.$checkboxGroup.querySelectorAll('input').length
    if (countChecked === countInputs) {
      this.disableSelectAllButton()
    } else {
      this.enableSelectAllButton()
    }
  }

  handleSelectAll () {
    const inputWrappers = this.$checkboxGroup.querySelectorAll('.js-checkbox-group__input-wrapper')

    inputWrappers.forEach(inputWrapper => {
      const $input = inputWrapper.querySelector('.c-checkbox__input')
      $input.setAttribute('checked', true)
    })

    this.disableSelectAllButton()
    this.enableClearAllButton()
  }

  handleClearAll () {
    const inputWrappers = this.$checkboxGroup.querySelectorAll('.js-checkbox-group__input-wrapper')

    inputWrappers.forEach(inputWrapper => {
      const $input = inputWrapper.querySelector('.c-checkbox__input')

      $input.removeAttribute('checked')
    })

    this.$checkboxGroup.querySelectorAll('.js-checkbox-group__select-all').forEach(selectAll => {
      selectAll.classList.remove('c-checkbox-group__select-all-selected')
    })

    this.enableSelectAllButton()
    this.disableClearAllButton()
  }

  disableSelectAllButton () {
    this.$selectAll.classList.add('c-checkbox-group__select-all-disabled', 'c-button--disabled')
    this.$selectAll.setAttribute('disabled', 'disabled')
  }

  enableSelectAllButton () {
    this.$selectAll.classList.remove('c-checkbox-group__select-all-disabled', 'c-button--disabled')
    this.$selectAll.removeAttribute('disabled')
  }

  disableClearAllButton () {
    this.$clearAll.classList.add('c-checkbox-group__clear-all-disabled', 'c-button--disabled')
    this.$clearAll.setAttribute('disabled', 'disabled')
  }

  enableClearAllButton () {
    this.$clearAll.classList.remove('c-checkbox-group__clear-all-disabled', 'c-button--disabled')
    this.$clearAll.removeAttribute('disabled')
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-checkbox-group').each((index, target) => {
    const $checkboxGroup = $(target)
    if (!$checkboxGroup.data('initialized')) {
      const klass = new DesignSystemCheckboxGroup($checkboxGroup)
      klass.events()
      $checkboxGroup.data('initialized', true)
    }
  })
})
