function debounceAsync(func, delay) {
  let timer;
  return async function(...args) {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      try {
        await func.apply(this, args);
      } catch (error) {
        console.error(error);
      }
    }, delay);
  }
}

export { debounceAsync };
