// /* global $ */

class DesignSystemCabinet {
  constructor ($wrapper) {
    this.wrapper = $wrapper[0]
  }

  init () {
    this.handleCabinetPreOpens()

    const cabinetCase = this.wrapper.querySelector('.js-cabinet-case')
    cabinetCase.addEventListener('click', () => {
      this.handleCabinet()
    })
  }

  handleCabinetPreOpens () {
    $('.js-cabinet').each(() => {
      if (this.wrapper.getAttribute('data-pre-open') == 'true') {
        this.openCabinet()
      }
    })
  }

  handleCabinet () {
    if (this.wrapper.classList.contains('is-open')) {
      this.closeCabinet()
    } else {
      this.openCabinet()
    }
  }

  openCabinet() {
    if (this.wrapper.getAttribute('data-openable') == 'true') {
      this.wrapper.classList.add('is-open')
    }
  }

  closeCabinet() {
    this.wrapper.classList.remove('is-open');
  }

}

$(document).on('ready ajaxComplete', () => {
  $('.js-cabinet').each((_, target) => {
    const $cabinet = $(target)
    if (!$cabinet.data('initialized')) {
      $cabinet.data('instance', new DesignSystemCabinet($cabinet))
      $cabinet.data('instance').init()
      $cabinet.data('initialized', true)
    }
  })
})
