//--------------------------------------------------
// Dismiss flash message when you click the close btn
//--------------------------------------------------

$(document).on('ready ajaxComplete', function() {
  $('.js-dismiss-alert').on('click', function() {
    var alert = $(this).closest('.alert-dismissible');
    alert.slideUp(200);
  });
});
