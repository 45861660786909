// ? These menu toggles are used by charlie talks?
// ? Menu classes here are deprecated, so these might not do anything?

$(document).on('ready ajaxComplete', function() {

  $('.js-mobile-charlie').off().on('click', function() {
    $(this).toggleClass('is-active');
  });

  $('.js-go-to-sickday').off().on('click', function() {
    $('.js-mobile-charlie').removeClass('is-active');

    var scrollTo = $('#sickday').offset().top - 50;
    $('.page-wrapper').animate({ scrollTop: scrollTo }, 500);
  });


  //// Toggle onboarding mobile menu
  $('.js-onboarding-mobile-nav-btn').off().on('click', function() {
    $('.js-onboarding-mobile-nav').toggleClass('is-active');
  });

  // Go to page from mobile menu
  $('.js-onboarding-mobile-nav').off().on('click', function() {
    $('.js-onboarding-mobile-nav').removeClass('is-active');
  });
});
