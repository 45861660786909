/* global $ */

class DesignSystemButtonGroup {
  constructor ($button) {
    this.button = $button
    this.id = $button.data('id')
    this.$dropdown = $(`.js-dropdown[data-id="${this.id}"]`)
  }

  init () {
    $(document).on('click', (event) => {
      const isDropdownOpen = this.$dropdown.hasClass('is-open')
      const clickedOutsideButton = !$(event.target).closest(this.button).length
      const clickedOutsideDropwdown = !$(event.target).closest(this.$dropdown).length

      if (clickedOutsideButton && clickedOutsideDropwdown) {
        if (isDropdownOpen) {
          this.closeDropdown()
        }
      }
    })

    this.button.on('click', () => {
      this.toggleDropdown()
    })
  }

  closeDropdown () {
    this.$dropdown.removeClass('is-open')
    this.button.removeClass('is-open')
  }

  openDropdown () {
    this.$dropdown.addClass('is-open')
    this.button.addClass('is-open')
  };

  toggleDropdown () {
    const isOpen = this.$dropdown.hasClass('is-open')

    if (isOpen) {
      this.closeDropdown()
    } else {
      this.openDropdown()
    }
  }
}

$(document).on('ready ajaxComplete', () => {
  $('.js-open-dropdown').each((_, target) => {
    if (!$(target).data('initialized')) {
      $(target).data('initialized', true)
      new DesignSystemButtonGroup($(target)).init()
    }
  })
})
